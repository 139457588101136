import IHandler from './IHandler';
import { GetListParams, GetListResult, RaRecord } from 'react-admin';
import { getJson } from '../../../utils/api';

export default class CreditReportingHandler implements IHandler {
  static prevNextToken: Record<number, string> = {};

  static async getListHandler<RecordType extends RaRecord = any>(
    resource?: string,
    params?: GetListParams
  ): Promise<GetListResult<RecordType>> {
    const query: { [key: string]: any } = {
      ...params?.filter,
      limit: params?.pagination.perPage ?? 100,
    };

    const page = params?.pagination.page ?? 1;
    if (page > 1) {
      query.nextToken = CreditReportingHandler.prevNextToken[page - 1];
    }

    for (const param in query) {
      if (query[param] === undefined || query[param] === null || query[param] === '') {
        delete query[param];
      }
    }

    const userId = params!.meta!.userId;

    return getJson(
      `/admin/users/${userId}/records?${new URLSearchParams(query).toString()}`,
      process.env.REACT_APP_BASE_CREDIT_REPORTING_API_URL
    ).then(async (response) => {
      let { data: responseData } = await response.json();
      const nextToken = responseData?.nextToken;
      CreditReportingHandler.prevNextToken[page] = JSON.stringify(nextToken);
      responseData = responseData?.rows?.map((data: any) => {
        const result = {
          ...data,
        };

        result.id = `${data.userId}-${data.reportKey}`;
        return {
          ...result,
        };
      });

      return {
        data: responseData,
        pageInfo: {
          hasNextPage: !!nextToken,
          nextPageToken: nextToken ?? undefined,
        },
      };
    });
  }
}
