import { GetListResult, GetOneParams, GetOneResult, RaRecord, UpdateParams, UpdateResult } from 'react-admin';
import { getJson, putJson } from '../../../utils/api';
import IHandler from './IHandler';

export default class GroupListHandler implements IHandler {
  static route = '/admin/groups';
  static resourceIdName = 'name';

  static async getOneHandler<RecordType extends RaRecord = any>(
    resource?: string,
    params?: GetOneParams
  ): Promise<GetOneResult<RecordType>> {
    const id = params?.id;

    return getJson(`/admin/users/${id}/groups`, process.env.REACT_APP_API_BASE_URL).then(async (response) => {
      const data = await response.json();
      const result = {
        ...data,
      };
      result.id = data['userId'];
      delete result[this.resourceIdName];
      return {
        data: {
          ...result,
        },
      };
    });
  }

  static async getListHandler<RecordType extends RaRecord = any>(): Promise<GetListResult<RecordType>> {
    return getJson(`${this.route}`, process.env.REACT_APP_API_BASE_URL)
      .then(async (response) => {
        let { data: responseData } = await response.json();
        responseData = responseData?.data?.map((data: any) => {
          const result = {
            ...data,
          };

          result.id = data[this.resourceIdName];
          delete result[this.resourceIdName];
          return {
            ...result,
          };
        });
        return {
          data: responseData,
          pageInfo: {
            hasNextPage: false,
            nextPageToken: undefined,
          },
        };
      })
      .catch((response) => {
        if (response.status === 400) {
          return {
            data: [],
            total: 0,
          };
        }
        return Promise.reject({
          status: response.status,
          error: response.statusText,
          message: response.statusText,
        });
      });
  }

  static async updateHandler<RecordType extends RaRecord = any>(
    resource: string,
    updateParams: UpdateParams
  ): Promise<UpdateResult<RecordType>> {
    const { id } = updateParams;
    const groups = updateParams.data;
    return putJson(`/admin/users/${id}/groups`, groups, process.env.REACT_APP_API_BASE_URL).then(async (response) => {
      const { data } = await response.json();

      const result = {
        ...data,
      };
      result.id = data['userId'];
      delete result[this.resourceIdName];
      return {
        data: {
          ...result,
        },
      };
    });
  }
}
