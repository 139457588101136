import {
  Datagrid,
  DateField,
  Empty,
  FunctionField,
  List,
  NumberField,
  Pagination,
  TextField,
  useRecordContext,
} from 'react-admin';
import { useParams } from 'react-router-dom';
import * as React from 'react';
import dayjs from 'dayjs';

const PostPagination = () => <Pagination rowsPerPageOptions={[10, 25, 50, 100]} />;

export const CreditReportingHistory = () => {
  const params = useParams();
  const record = useRecordContext();

  const userId = params.userId ?? record?.id;
  if (!userId) {
    return <Empty />;
  }

  const currency = 'GBP';
  const parseDateRender = (column: string) => (record: any) => {
    const parts = record[column].split('-');
    return dayjs(`${parts[1]}/01/${parts[0]}`).format('MM/DD/YYYY');
  };

  return (
    <List
      resource={'creditreporting'}
      queryOptions={{ meta: { userId } }}
      hasCreate={false}
      hasEdit={false}
      hasShow={false}
      perPage={100}
      pagination={<PostPagination />}
    >
      <Datagrid bulkActionButtons={false}>
        <FunctionField source="reportingPeriod" render={parseDateRender('reportingPeriod')} label={'Period'} />
        <TextField source="type" label="Type" />
        <TextField source="reportRow.accountNumber" label="AccountNumber" />
        <NumberField
          source="reportRow.currentBalance"
          options={{ style: 'currency', currency }}
          label="CurrentBalance"
          fullWidth
        />
        <TextField source="reportRow.accountStatusCode" label="AccountStatusCode" />
        <DateField source="reportRow.startDate" label="StartDate" />
        <DateField source="reportRow.closeDate" label="CloseDate" />
      </Datagrid>
    </List>
  );
};
