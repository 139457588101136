import IHandler from './IHandler';
import { GetListParams, GetListResult, RaRecord } from 'react-admin';
import { getJson, postJson } from '../../../utils/api';

export interface SyncPaymentsResult {
  changeStatusNeeded: boolean;
  newStatus?: string;
}
export default class PaymentListHandler implements IHandler {
  static prevNextToken: Record<number, string> = {};
  static route = '/admin/payments/list';

  static async getListHandler<RecordType extends RaRecord = any>(
    resource?: string,
    params?: GetListParams
  ): Promise<GetListResult<RecordType>> {
    const query: { [key: string]: any } = {
      limit: params?.pagination.perPage ?? 10,
      ...params?.filter,
    };

    const page = params?.pagination.page ?? 1;
    if (page > 1 && PaymentListHandler.prevNextToken[page - 1] != null) {
      query['nextToken'] = PaymentListHandler.prevNextToken[page - 1];
    }
    return getJson(`${this.route}?${new URLSearchParams(query).toString()}`, process.env.REACT_APP_BASE_PAYMENT_API_URL)
      .then(async (response) => {
        const { data: responseData } = await response.json();
        const nextToken = responseData?.nextToken;
        PaymentListHandler.prevNextToken[page] = JSON.stringify(nextToken);
        return {
          data: responseData.payments ?? [],
          pageInfo: {
            hasNextPage: !!nextToken,
            nextPageToken: nextToken ?? undefined,
          },
        };
      })
      .catch((response) => {
        if (response.status === 400) {
          return {
            data: [],
            total: 0,
          };
        }
        return Promise.reject({
          status: response.status,
          error: response.statusText,
          message: response.statusText,
        });
      });
  }

  static async syncPayment(userId: string, paymentId: string, isDryRun: boolean) {
    return postJson(`/admin/wallet/${userId}/sync`, { paymentId, isDryRun }, process.env.REACT_APP_BASE_PAYMENT_API_URL)
      .then(async (response) => {
        const { data } = await response.json();

        return data as SyncPaymentsResult;
      })
      .catch((response) => {
        console.log(
          `${response.status}:${response.error.message ?? JSON.stringify(response.error, null, 2)}:${response.message}`
        );
        return Promise.reject({
          status: response.status,
          error: response.statusText,
          message: response.error?.errorMessage ?? response.statusText,
        });
      });
  }
}
