import IHandler from './IHandler';
import { GetListParams, GetListResult, GetOneParams, GetOneResult, RaRecord } from 'react-admin';
import { getJson, postJson } from '../../../utils/api';

export default class DataMismatchUserHandler implements IHandler {
  static prevNextToken: Record<number, string> = {};
  static route = '/admin/users/mismatch';
  static resourceIdName = 'userId';

  static async getListHandler<RecordType extends RaRecord = any>(
    resource?: string,
    params?: GetListParams
  ): Promise<GetListResult<RecordType>> {
    const query: { [key: string]: any } = {
      limit: params?.pagination.perPage ?? 10,
      ...params?.filter,
    };

    const page = params?.pagination.page ?? 1;
    if (page > 1 && DataMismatchUserHandler.prevNextToken[page - 1] != null) {
      query['nextToken'] = DataMismatchUserHandler.prevNextToken[page - 1];
    }
    return getJson(`${this.route}?${new URLSearchParams(query).toString()}`, process.env.REACT_APP_BASE_KYC_API_URL)
      .then(async (response) => {
        let { data: responseData } = await response.json();
        const nextToken = responseData?.nextToken;
        DataMismatchUserHandler.prevNextToken[page] = nextToken;
        responseData = responseData?.users?.map((data: any) => {
          const result = {
            ...data,
          };
          result.id = data[this.resourceIdName];
          delete result[this.resourceIdName];
          return {
            ...result,
          };
        });

        return {
          data: responseData,
          pageInfo: {
            hasNextPage: !!nextToken,
            nextPageToken: nextToken ?? undefined,
          },
        };
      })
      .catch((response) => {
        if (response.status === 400) {
          return {
            data: [],
            total: 0,
          };
        }
        return Promise.reject({
          status: response.status,
          error: response.statusText,
          message: response.statusText,
        });
      });
  }

  static async getOneHandler<RecordType extends RaRecord = any>(
    resource: string,
    params?: GetOneParams
  ): Promise<GetOneResult<RecordType>> {
    const id = params?.id;
    return getJson(`${this.route}?userId=${id}`, process.env.REACT_APP_BASE_KYC_API_URL).then(async (response) => {
      const { data } = await response.json();

      const d = data.users[0];

      d.id = d[this.resourceIdName];
      delete d[this.resourceIdName];
      console.log(d);
      return {
        data: {
          ...d,
        },
      };
    });
  }

  static async review(userIds: string[]) {
    return postJson(this.route, { userIds }, process.env.REACT_APP_BASE_KYC_API_URL)
      .then(async (response) => {
        const { data: responseData } = await response.json();
        return {
          data: responseData,
          pageInfo: {},
        };
      })
      .catch((response) => {
        if (response.status === 400) {
          return {
            data: [],
            total: 0,
          };
        }
        return Promise.reject({
          status: response.status,
          error: response.statusText,
          message: response.statusText,
        });
      });
  }
}
