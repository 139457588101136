export interface BitsUser {
  firstName: string;
  lastName: string;
  title: string;
  email: string;
  address1: string;
  address2: string;
  address3: string;
  city: string;
  postCode: string;
  state: string;
  country: string;
  dateBirth: string;
  phoneNumber: string;
  membershipNumber: string;
  status: string;
  verificationStatus: string;
  hasID: boolean;
  hasStoreAccess: boolean;
  storeIDVerificationRequired: boolean;
  emergencyContact: string;
  referredBy: string;
  loginVerified: boolean;
  createdAt: string;
  referral: string;
}

export interface UserId {
  userId: string;
}

export enum UserScoreStatus {
  enabled = 'enabled',
  pending = 'pending',
  failed = 'failed',
  disabled = 'disabled',
}

export interface UserProps {
  status: UserScoreStatus;
  lastScore: number | null;
  lastDataFetchTimestamp: string | null;
  userDetails: BitsUser;
  lastError?: any | null;
  lastErrorTimestamp?: string | null;
  dateCreate?: string | null;
  dateActivate?: string | null;
}

export type UserModel = UserId & UserProps;
