import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Grid,
  Button,
  Select,
  MenuItem,
} from '@mui/material';
import IconCancel from '@mui/icons-material/Cancel';
import IconSave from '@mui/icons-material/Save';
import { useState } from 'react';
import { useNotify, useRefresh } from 'react-admin';
import { SelectChangeEvent } from '@mui/material/Select';
import SubscriptionHandler from '../../react-admin/providers/handlers/SubscriptionHandler';

const useChangePaymentDay = ({
  setShow,
  userId,
}: {
  setShow: (flag: boolean) => void;
  userId: string | undefined | null;
}) => {
  const [reason, setReason] = useState('');
  const [paymentDay, setPaymentDay] = useState<string>('');
  const [loading, setLoading] = useState(false);
  const notify = useNotify();

  const refresh = useRefresh();
  const close = () => {
    setLoading(false);
    setReason('');
    setPaymentDay('');
    setShow(false);
  };
  const changePaymentDay = async () => {
    setLoading(true);
    if (paymentDay !== '' && userId) {
      SubscriptionHandler.changePaymentDay(
        userId,
        {
          paymentDay,
          reason: reason === '' ? undefined : reason,
        },
        () => {
          refresh();
          close();
        },
        (e: any) => {
          notify(e.toString(), { type: 'error' });
          close();
        }
      );
    } else {
      close();
    }
  };

  return {
    reason,
    setReason: (event: any) => setReason(event.target.value),
    paymentDay,
    setPaymentDay: (value: string) => setPaymentDay(value),
    changePaymentDay,
    close,
    loading,
  };
};

export const ChangePaymentDayAction = ({ wallet }: { wallet: any }) => {
  const [show, setShow] = useState(false);
  const { reason, setReason, paymentDay, setPaymentDay, changePaymentDay, close, loading } = useChangePaymentDay({
    setShow,
    userId: wallet.id,
  });

  const paymentDayChoices = Array.from(Array(28).keys()).map((x) => (x + 1).toString());
  paymentDayChoices.push('last');
  return (
    <>
      <Button color="primary" onClick={() => setShow(true)}>
        Change Payment Day
      </Button>
      <Dialog fullWidth open={show} onClose={setShow} aria-label="Change Payment Day">
        <DialogTitle>Change Payment Day</DialogTitle>
        <DialogContent>
          <Grid item xs={12}>
            <TextField
              id="reason"
              placeholder="Reason"
              variant="outlined"
              fullWidth
              value={reason}
              onChange={setReason}
            />
            <Select
              id="paymentDay"
              fullWidth
              value={paymentDay ?? ''}
              onChange={(event: SelectChangeEvent) => setPaymentDay(event.target.value as string)}
            >
              {paymentDayChoices.map((d) => (
                <MenuItem key={d} value={d}>
                  {d}
                </MenuItem>
              ))}
            </Select>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={close} disabled={loading}>
            <IconCancel />
            <span>Close</span>
          </Button>
          <Button onClick={changePaymentDay} disabled={!paymentDay || loading}>
            <IconSave />
            <span>Save</span>
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
