import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';
import IconCancel from '@mui/icons-material/Cancel';
import IconSave from '@mui/icons-material/Save';
import { useState } from 'react';
import { useNotify, useRefresh } from 'react-admin';
import UserListHandler from '../../react-admin/providers/handlers/UserListHandler';

const useUpdateUserTag = ({
  setShow,
  userId,
  tag,
  tagValue,
}: {
  setShow: (flag: boolean) => void;
  userId: string | undefined;
  tag: string;
  tagValue: boolean;
}) => {
  const [loading, setLoading] = useState(false);
  const [reason, setReason] = useState('');
  const notify = useNotify();

  const refresh = useRefresh();
  const close = () => {
    setLoading(false);
    setReason('');
    setShow(false);
  };
  const updateTag = async () => {
    setLoading(true);
    if (userId != null) {
      UserListHandler.setTag(
        userId,
        tag,
        tagValue,
        reason,
        () => {
          refresh();
          close();
        },
        (e: any) => {
          notify(e.toString(), { type: 'error' });
          close();
        }
      );
    } else {
      close();
    }
  };

  return {
    updateTag,
    close,
    loading,
    reason,
    setReason: (event: any) => setReason(event.target.value),
  };
};

export const UpdateUserTagAction = ({
  user,
  tag,
  tagValue,
  tagLabel,
  children,
}: {
  user: any;
  tag: string;
  tagLabel: string;
  tagValue: boolean;
  children?: any;
}) => {
  const [show, setShow] = useState(false);
  const { reason, setReason, updateTag, close, loading } = useUpdateUserTag({
    setShow,
    userId: user.id,
    tag,
    tagValue,
  });
  return (
    <>
      <Button color="primary" onClick={() => setShow(true)}>
        {children}
      </Button>
      <Dialog fullWidth open={show} onClose={setShow} aria-label={tagLabel}>
        <DialogTitle>Tag update</DialogTitle>
        <DialogContent>
          Are you sure you want to {tagValue ? 'add' : 'remove'} tag {tagLabel}?
          <TextField
            id="reason"
            placeholder="Reason"
            variant="outlined"
            fullWidth
            value={reason}
            onChange={setReason}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={close} disabled={loading}>
            <IconCancel />
            <span>Close</span>
          </Button>
          <Button onClick={updateTag} disabled={loading || !reason}>
            <IconSave />
            <span>Save</span>
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
