import { BooleanField, EditButton, NumberField, Show, SimpleShowLayout, TextField, TopToolbar } from 'react-admin';
import { Card, CardContent, Grid } from '@mui/material';
import { BackButton } from '../../react-admin/components/BackButton';
import { useResourceAccess } from '../../hooks/useAccess';

const Actions = () => {
  const { canManagePlans } = useResourceAccess();

  return (
    <TopToolbar>
      {canManagePlans() && <EditButton />}
      <BackButton />
    </TopToolbar>
  );
};

export const SubscriptionPlanDetails = () => (
  <Card>
    <CardContent>
      <Show actions={<Actions />}>
        <Grid container spacing={2}>
          <Grid xs={6} item>
            <SimpleShowLayout>
              <TextField source="name" />
              <TextField source="title" />
              <TextField source="subtitle" />
              <TextField source="availability" />
              <NumberField source="amount" />
              <NumberField source="creditAmount" />
              <NumberField source="order" />
              <TextField source="currency" />
              <TextField source="interval" />
              <BooleanField source="isDefault" />
              <BooleanField source="isEnabled" />
              <TextField source="stripePlanId" />
              <TextField source="renewalPeriod" />
              <NumberField source="orderLimitPerHour" />
              <NumberField source="orderLimitPerDay" />
              <NumberField source="orderLimitPerPeriod" />
              <NumberField source="creditCardLimit" />
              <BackButton />
            </SimpleShowLayout>
          </Grid>
        </Grid>
      </Show>
    </CardContent>
  </Card>
);
