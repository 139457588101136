export const Access = (permissions: string[] | undefined) => {
  return {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    canEdit: (resource?: string) => {
      return permissions?.includes(`user-admin-edit`) || permissions?.includes(`super-admin`);
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    canExport: (resource?: string) => {
      return permissions?.includes(`user-admin-export`) || permissions?.includes(`super-admin`);
    },
    canViewHistory: () => {
      return permissions?.includes(`super-admin`);
    },
    canViewDocument: () => {
      return permissions?.includes(`user-admin-edit`) || permissions?.includes(`super-admin`);
    },
    canEditSettings: () => {
      return permissions?.includes(`super-admin`);
    },
    canEditKYC: () => {
      return permissions?.includes(`user-admin-edit`) || permissions?.includes(`super-admin`);
    },
    canChangePermissions: () => {
      return permissions?.includes(`super-admin`);
    },
    canChargeAll: () => {
      return permissions?.includes(`user-admin-edit`) || permissions?.includes(`super-admin`);
    },
    canCancelSubscription: () => {
      return permissions?.includes(`super-admin`) || permissions?.includes(`user-admin-edit`);
    },
    canUpdateBalance: () => {
      return permissions?.includes(`super-admin`) || permissions?.includes(`user-admin-edit`);
    },
    canViewAnalytics: () => {
      return permissions?.includes(`super-admin`);
    },
    canManagePlans: () => {
      return permissions?.includes(`super-admin`);
    },
  };
};

export const getRoleDescription = (role: string): string[] => {
  switch (role) {
    case `super-admin`:
      return [`Can do everything`, `Can edit settings`, `Can view full history`, `Can change permissions`];
    case `user-admin`:
      return [
        `Can login to Admin ***`,
        `Can view user details`,
        `Can view payment information`,
        `Can use reminder`,
        `Can view score`,
      ];
    case `user-admin-edit`:
      return [`Can edit user details`, `Can change wallet`, `Can manage KYC`, `Can charge all`, `Can manage documents`];
    case `user-admin-export`:
      return [`Can export (not implemented)`];
    case `credit-card-admin`:
      return [`Can get access to the credit card product`];
    default:
      return [];
  }
};
