import {
  BooleanField,
  DateField,
  EditButton,
  NumberField,
  Show,
  SimpleShowLayout,
  TextField,
  useRecordContext,
} from 'react-admin';
import { useResourceAccess } from '../../hooks/useAccess';

const StoreConfigDetailsActions = () => {
  const record = useRecordContext();
  const { canEditSettings } = useResourceAccess();

  if (record == null) {
    return <></>;
  }

  if (!canEditSettings()) {
    return <></>;
  }

  return <EditButton to={`/config/store/edit`} label={'Edit'}></EditButton>;
};

export const StoreConfigDetails = ({ configId }: { configId: string }) => {
  return (
    <Show resource="storesettings" id={configId} actions={<StoreConfigDetailsActions />}>
      <SimpleShowLayout>
        <NumberField source="orderPaymentSplitPercent" />
        <BooleanField source="idRequired" />
        <BooleanField source="allowAccessForEveryone" />
        <BooleanField source="allowAccessForStaff" />
        <DateField showTime={false} source="allowAccessForSubscriptionCreatedBefore" label="Date" />
        <TextField source="allowAccessForSubscriptionPlans" />
      </SimpleShowLayout>
    </Show>
  );
};
