import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import IconCancel from '@mui/icons-material/Cancel';
import IconSave from '@mui/icons-material/Save';
import { useState } from 'react';
import { useNotify, useRefresh } from 'react-admin';
import UserListHandler from '../../react-admin/providers/handlers/UserListHandler';

const useBlockUser = ({ setShow, userId }: { setShow: (flag: boolean) => void; userId: string | undefined }) => {
  const [loading, setLoading] = useState(false);
  const notify = useNotify();

  const refresh = useRefresh();
  const close = () => {
    setLoading(false);
    setShow(false);
  };
  const blockUser = async () => {
    setLoading(true);
    if (userId != null) {
      UserListHandler.blockUser(
        userId,
        () => {
          refresh();
          close();
        },
        (e: any) => {
          notify(e.toString(), { type: 'error' });
          close();
        }
      );
    } else {
      close();
    }
  };

  return {
    blockUser,
    close,
    loading,
  };
};

export const BlockUserAction = ({ user }: { user: any }) => {
  const [show, setShow] = useState(false);
  const { blockUser, close, loading } = useBlockUser({
    setShow,
    userId: user.id,
  });
  return (
    <>
      <Button color="primary" onClick={() => setShow(true)}>
        Block
      </Button>
      <Dialog fullWidth open={show} onClose={setShow} aria-label="Block User">
        <DialogTitle>Block user</DialogTitle>
        <DialogContent>Are you sure you want to block the user?</DialogContent>
        <DialogActions>
          <Button onClick={close} disabled={loading}>
            <IconCancel />
            <span>Close</span>
          </Button>
          <Button onClick={blockUser} disabled={loading}>
            <IconSave />
            <span>Save</span>
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
