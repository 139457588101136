import { EditButton, NumberField, Show, SimpleShowLayout, useRecordContext } from 'react-admin';

const CrawlerConfigDetailsActions = () => {
  const record = useRecordContext();
  if (record == null) {
    return <></>;
  }

  return <EditButton to={`/config/crawler/edit`} label={'Edit'}></EditButton>;
};

export const CrawlerConfigDetails = ({ configId }: { configId: string }) => {
  return (
    <Show resource="settings" id={configId} actions={<CrawlerConfigDetailsActions />}>
      <SimpleShowLayout>
        <NumberField source="priceMarginPercent" />
        <NumberField source="priceMarginAmount" />
        <NumberField source="minPrice" />
        <NumberField source="maxPrice" />
      </SimpleShowLayout>
    </Show>
  );
};
