import IHandler from './IHandler';
import {
  CreateParams,
  CreateResult,
  GetListParams,
  GetListResult,
  GetManyParams,
  GetManyResult,
  GetOneParams,
  GetOneResult,
  RaRecord,
  UpdateParams,
  UpdateResult,
} from 'react-admin';
import { getJson, postJson, processApiError, putJson } from '../../../utils/api';

export default class SubscriptionPlanHandler implements IHandler {
  static prevNextToken: Record<number, string> = {};
  static route = '/plans';
  static resourceIdName = 'subscriptionPlanId';

  static async getListHandler<RecordType extends RaRecord = any>(
    resource?: string,
    params?: GetListParams
  ): Promise<GetListResult<RecordType>> {
    const {
      meta: { selectAll },
    } = params ?? {};
    let method;
    let page: number | null = null;
    if (selectAll) {
      method = getJson(`${this.route}/all`, process.env.REACT_APP_BASE_PAYMENT_API_URL);
    } else {
      const query: { [key: string]: any } = {
        limit: params?.pagination.perPage ?? 10,
        ...params?.filter,
      };

      page = params?.pagination.page ?? 1;
      if (page > 1 && SubscriptionPlanHandler.prevNextToken[page - 1] != null) {
        query['nextToken'] = SubscriptionPlanHandler.prevNextToken[page - 1];
      }
      method = getJson(
        `${this.route}?${new URLSearchParams(query).toString()}`,
        process.env.REACT_APP_BASE_PAYMENT_API_URL
      );
    }
    return method
      .then(async (response) => {
        let { data: responseData } = await response.json();
        const nextToken = responseData?.nextToken;
        if (page != null) {
          SubscriptionPlanHandler.prevNextToken[page] = JSON.stringify(nextToken);
        }
        responseData = responseData?.plans?.map((data: any) => {
          const result = {
            ...data,
          };

          result.id = data[this.resourceIdName];
          delete result[this.resourceIdName];
          return {
            ...result,
          };
        });
        return {
          data: responseData,
          pageInfo: {
            hasNextPage: !!nextToken,
            nextPageToken: nextToken ?? undefined,
          },
        };
      })
      .catch((response) => {
        if (response.status === 400) {
          return {
            data: [],
            total: 0,
          };
        }
        return Promise.reject({
          status: response.status,
          error: response.statusText,
          message: response.statusText,
        });
      });
  }

  static async getOneHandler<RecordType extends RaRecord = any>(
    resource: string,
    params?: GetOneParams<any>
  ): Promise<GetOneResult<RecordType>> {
    const id = params?.id;
    return getJson(`${this.route}?planId=${id}`, process.env.REACT_APP_BASE_PAYMENT_API_URL)
      .then(async (response) => {
        const { data } = await response.json();

        const d = data.plans[0];

        d.id = d[this.resourceIdName];
        delete d[this.resourceIdName];

        return {
          data: {
            ...d,
          },
        };
      })
      .catch(processApiError);
  }

  static async updateHandler<RecordType extends RaRecord = any>(
    resource: string,
    params: UpdateParams<any>
  ): Promise<UpdateResult<RecordType>> {
    const { id } = params;
    params.id = id === 'undefined' ? '' : id;

    const payload = params.data;

    return putJson(`${this.route}/${id}`, payload, process.env.REACT_APP_BASE_PAYMENT_API_URL)
      .then(async (response) => {
        const { data } = await response.json();

        const d = data;
        d.id = d[this.resourceIdName];
        delete d[this.resourceIdName];

        return {
          data: {
            ...d,
          },
        };
      })
      .catch(processApiError);
  }

  static async createHandler<RecordType extends RaRecord = any>(
    resource: string,
    params: CreateParams<any>
  ): Promise<CreateResult<RecordType>> {
    const payload = params.data;

    return postJson(`/${this.route}`, payload, process.env.REACT_APP_BASE_PAYMENT_API_URL)
      .then(async (response) => {
        const { data } = await response.json();
        const d = data;
        d.id = d[this.resourceIdName];
        delete d[this.resourceIdName];

        return {
          data: {
            ...d,
          },
        };
      })
      .catch(processApiError);
  }
  static async getMany<RecordType extends RaRecord = any>(
    resource: string,
    params: GetManyParams
  ): Promise<GetManyResult<RecordType>> {
    const { ids } = params;

    return SubscriptionPlanHandler.getListHandler(resource, {
      pagination: {
        perPage: 100,
        page: 1,
      },
      filter: {},
      sort: {
        field: 'name',
        order: 'ASC',
      },
    }).then((response) => {
      return {
        data: response.data
          .filter((d: any) => ids.includes(d.stripePlanId))
          .map((d: any) => ({
            ...d,
            id: d.stripePlanId,
          })),
      };
    });
  }
}
