import { Admin, CustomRoutes, Resource } from 'react-admin';
import { Layout } from './react-admin/components/Layout';
import dataProvider from './react-admin/providers/dataProvider';
import { UserList } from './components/UserList';
import { DocumentList } from './components/DocumentList';
import { Route } from 'react-router';
import { DocumentUpload } from './components/DocumentUpload';
import { UserDetails } from './components/UserDetails';
import { KycList } from './components/KycList';
import { CrawlerConfigDetails } from './components/CrawlerConfigDetails';
import { CrawlerConfigEdit } from './components/CrawlerConfigEdit';
import { BrowserRouter } from 'react-router-dom';
import { createAuthProvider } from './react-admin/providers/authProvider';
import { CognitoLogin } from './components/CognitoLogin';
import { UserEdit } from './components/UserEdit';
import { KycDetails } from './components/KycDetails';
import { ScoreList } from './components/ScoreList';
import { SubscriptionDetails } from './components/SubscriptionDetails';
import { SubscriptionList } from './components/SubscriptionList';
import { PaymentList } from './components/PaymentList';
import { HistoryList } from './components/HistoryList';
import { SubscriptionListReminder } from './components/SubscriptionListReminder';
import { CardVerificationList } from './components/CardVerificationList';
import { CreditReportingHistory } from './components/CreditReportingHistory';
import { StoreConfigDetails } from './components/StoreConfigDetails';
import { StoreConfigEdit } from './components/StoreConfigEdit';
import { Analytics } from './components/Analytics';
import { DataMismatchUserList } from './components/DataMismatchUserList';
import { PepSanctionsUserList } from './components/PepSanctionsList';
import { SubscriptionPlanList } from './components/SubscriptionPlanList';
import { SubscriptionPlanDetails } from './components/SubscriptionPlanDetails';
import { SubscriptionPlanEdit } from './components/SubscriptionPlanEdit';
import { SubscriptionPlanCreate } from './components/SubscriptionPlanCreate';
import { SanctionsFileUpload } from './components/SanctionsFileUpload';

const provider = createAuthProvider();

const App = () => {
  return (
    <BrowserRouter>
      <Admin
        layout={Layout}
        dataProvider={dataProvider}
        authProvider={provider}
        loginPage={CognitoLogin}
        disableTelemetry
        title="User Management Admin"
      >
        {(permissions) => (
          <>
            <Resource name="subscriptions" list={SubscriptionList} show={SubscriptionDetails} hasCreate={false} />
            <Resource
              name="users"
              list={UserList}
              show={UserDetails}
              edit={permissions?.canEdit() ? UserEdit : undefined}
            />
            {permissions?.canViewDocument() ? (
              <Resource
                name="documents"
                list={DocumentList}
                create={permissions?.canEdit() ? DocumentUpload : undefined}
              />
            ) : null}
            <Resource name="score" list={ScoreList}></Resource>
            {permissions?.canEditKYC() && (
              <Resource name="kyc" list={KycList}>
                <Route path=":userId/history" element={<KycDetails />}></Route>
              </Resource>
            )}
            {permissions?.canEditSettings() && <Resource name="groups" />}
            <Resource
              name="plans"
              list={SubscriptionPlanList}
              show={SubscriptionPlanDetails}
              edit={SubscriptionPlanEdit}
              create={SubscriptionPlanCreate}
            />
            <Resource name="payments" list={PaymentList} />
            <Resource name="cardverification" list={CardVerificationList} hasCreate={false} />
            <Resource
              name="creditreporting"
              hasCreate={false}
              hasEdit={false}
              hasShow={false}
              list={CreditReportingHistory}
            />
            <Resource name="Analytics" hasCreate={false} hasEdit={false} hasShow={false} list={Analytics} />
            {permissions?.canEditKYC() && <Resource name="kycmismatch" list={DataMismatchUserList} />}
            {permissions?.canEditKYC() && (
              <Resource name="sanctions" list={PepSanctionsUserList} create={<SanctionsFileUpload />} />
            )}
            <CustomRoutes>
              <Route path="/users/:userId/documents" element={<DocumentList />} />
              <Route path="/users/:userId/creditreportinghistory" element={<CreditReportingHistory />} />
              {permissions?.canEditSettings() && (
                <Route path="/config/crawler" element={<CrawlerConfigDetails configId="crawler" />} />
              )}
              {permissions?.canEditSettings() && <Route path="/config/crawler/edit" element={<CrawlerConfigEdit />} />}
              <Route path="/config/store" element={<StoreConfigDetails configId="store" />} />
              {permissions?.canEditSettings() && <Route path="/config/store/edit" element={<StoreConfigEdit />} />}
              <Route path="/subscriptions/:userId/payments" element={<PaymentList />} />
              {permissions?.canViewHistory() && <Route path="/history/*" element={<HistoryList />} />}
              <Route path="/reminder/*" element={<SubscriptionListReminder />} />
            </CustomRoutes>
          </>
        )}
      </Admin>
    </BrowserRouter>
  );
};

export default App;
