import { ChipField, Datagrid, DateField, Empty, List, Pagination, TextField, useRecordContext } from 'react-admin';
import { useParams } from 'react-router-dom';
import * as React from 'react';

const PostPagination = () => <Pagination rowsPerPageOptions={[10, 25, 50, 100]} />;

export const EmailNotificationList = () => {
  const params = useParams();
  const record = useRecordContext();

  const userId = params.userId ?? record?.id;
  if (!userId) {
    return <Empty />;
  }

  return (
    <List
      resource={'emailnotifications'}
      queryOptions={{ meta: { userId } }}
      hasCreate={false}
      hasEdit={false}
      hasShow={false}
      perPage={100}
      pagination={<PostPagination />}
    >
      <Datagrid bulkActionButtons={false}>
        <DateField source="dateCreated" />
        <DateField source="dateDelivered" />
        <TextField source="recipient" label="Email" />
        <TextField source="message" />
        <ChipField source="status" />
        <TextField source="error" />
      </Datagrid>
    </List>
  );
};
