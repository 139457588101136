import IHandler from './IHandler';
import { GetListParams, GetListResult, RaRecord } from 'react-admin';
import { postJson } from '../../../utils/api';

export default class CardVerificationHandler implements IHandler {
  static prevNextToken: Record<number, string> = {};
  static route = '/admin/verification';
  static resourceIdName = 'userId';

  static async getListHandler<RecordType extends RaRecord = any>(
    resource?: string,
    params?: GetListParams
  ): Promise<GetListResult<RecordType>> {
    const query: { [key: string]: any } = {
      pageSize: params?.pagination.perPage ?? 100,
      ...params?.filter,
    };

    const page = params?.pagination.page ?? 1;
    if (page > 1 && CardVerificationHandler.prevNextToken[page - 1] != null) {
      query['nextToken'] = CardVerificationHandler.prevNextToken[page - 1];
    }
    return postJson(
      `${this.route}/search?${new URLSearchParams(query).toString()}`,
      {},
      process.env.REACT_APP_BASE_PAYMENT_API_URL
    )
      .then(async (response) => {
        let { data: responseData } = await response.json();

        const nextToken = responseData?.nextToken;
        CardVerificationHandler.prevNextToken[page] = JSON.stringify(nextToken);

        responseData = responseData?.wallets || [];
        responseData = responseData.map((data: any) => {
          const verified =
            data.paymentMethod?.kycVerified === undefined
              ? 'Not verified'
              : data.paymentMethod.kycVerified
              ? 'Verified'
              : 'Rejected';
          const result = {
            ...data,
            verified,
          };

          result.id = data[this.resourceIdName];
          delete result[this.resourceIdName];
          return {
            ...result,
          };
        });
        return {
          data: responseData,
          pageInfo: {
            hasNextPage: !!nextToken,
            nextPageToken: nextToken ?? undefined,
          },
        };
      })
      .catch((response) => {
        if (response.status === 400) {
          return {
            data: [],
            total: 0,
          };
        }
        return Promise.reject({
          status: response.status,
          error: response.statusText,
          message: response.statusText,
        });
      });
  }

  static rejectCardVerification = async (userIds: string[]) => {
    return postJson(`${this.route}/reject`, { userIds }, process.env.REACT_APP_BASE_PAYMENT_API_URL)
      .then(async (response) => {
        const { data: responseData } = await response.json();
        return {
          data: responseData,
          pageInfo: {},
        };
      })
      .catch((response) => {
        if (response.status === 400) {
          return {
            data: [],
            total: 0,
          };
        }
        return Promise.reject({
          status: response.status,
          error: response.statusText,
          message: response.statusText,
        });
      });
  };

  static approveCardVerification = async (userIds: string[]) => {
    return postJson(`${this.route}/approve`, { userIds }, process.env.REACT_APP_BASE_PAYMENT_API_URL)
      .then(async (response) => {
        const { data: responseData } = await response.json();
        return {
          data: responseData,
          pageInfo: {},
        };
      })
      .catch((response) => {
        if (response.status === 400) {
          return {
            data: [],
            total: 0,
          };
        }
        return Promise.reject({
          status: response.status,
          error: response.statusText,
          message: response.statusText,
        });
      });
  };
}
