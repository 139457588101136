import { Dialog, DialogTitle, DialogActions, Button } from '@mui/material';
import IconCancel from '@mui/icons-material/Cancel';
import IconSave from '@mui/icons-material/Save';
import { useState } from 'react';
import { useNotify, useRefresh } from 'react-admin';
import SubscriptionHandler from '../../react-admin/providers/handlers/SubscriptionHandler';

const useUpdateBalance = ({ setShow, userId }: { setShow: (flag: boolean) => void; userId: string | undefined }) => {
  const [loading, setLoading] = useState(false);
  const notify = useNotify();

  const refresh = useRefresh();
  const close = () => {
    setLoading(false);
    setShow(false);
  };
  const updateBalance = async () => {
    setLoading(true);
    if (userId !== undefined) {
      SubscriptionHandler.updateBalance(
        userId,
        () => {
          refresh();
          close();
        },
        (e: any) => {
          notify(e.toString(), { type: 'error' });
          close();
        }
      );
    } else {
      close();
    }
  };

  return {
    updateBalance,
    close,
    loading,
  };
};

export const UpdateBalanceAction = ({ wallet }: { wallet: any }) => {
  const [show, setShow] = useState(false);
  const { updateBalance, close, loading } = useUpdateBalance({
    setShow,
    userId: wallet.id,
  });
  return (
    <>
      <Button color="primary" onClick={() => setShow(true)}>
        Recalculate Balance
      </Button>
      <Dialog fullWidth open={show} onClose={setShow} aria-label="Recalculate balance">
        <DialogTitle>Recalculate balance</DialogTitle>
        <DialogActions>
          <Button onClick={close} disabled={loading}>
            <IconCancel />
            <span>Close</span>
          </Button>
          <Button onClick={updateBalance} disabled={loading}>
            <IconSave />
            <span>Save</span>
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
