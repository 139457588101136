export enum PromoCodesSearchTypes {
  NONE = 'none',
  EMAIL = 'email',
  CODE = 'code',
  USER_FULL_NAME = 'fullname',
}

export enum UserStatus {
  new = 'new',
  pending = 'pending',
  active = 'active',
  rejected = 'rejected',
  deleted = 'deleted',
  disabled = 'disabled',
}

export enum UserTitle {
  Mr = 'Mr',
  Ms = 'Ms',
  Mrs = 'Mrs',
  Miss = 'Miss',
  Mx = 'Mx',
  Baron = 'Baron',
  Lord = 'Lord',
  Dr = 'Dr',
}

export interface UserListResponse {
  id: string;
  userId: string;
  title: string;
  firstName: string;
  lastName: string;
  dateBirth: string;
  email: string;
  phoneNumber: string;
  status: UserStatus;
  isAdmin?: boolean;
  bitsId?: string;
  promoCode?: string;
}

export interface AdjustBalanceRequest {
  amount: number;
  reason: string;
}

export interface CursorResponse {
  LastEvaluatedKey: any;
}

export enum DocumentType {
  photo = 'photo',
  passport = 'passport',
  driving_license = 'driving_license',
  government_issued_id = 'government_issued_id',
  council_tax_bill = 'council_tax_bill',
  energy_bill = 'energy_bill',
  bank_statement = 'bank_statement',
  other = 'other',
  document = 'document',
  report = 'report',
  export = 'export',
}

export enum SubscriptionHistoryEventType {
  TrialSubscriptionCreated = 'TrialSubscriptionCreateStarted',
  SubscriptionCreateStarted = 'SubscriptionCreateStarted',
  SubscriptionChangeStarted = 'SubscriptionChangeStarted',
  SubscriptionCreateFailed = 'SubscriptionCreateFailed',
  SubscriptionCreated = 'SubscriptionCreated',
  SubscriptionCancelled = 'SubscriptionCancelled',
  SubscriptionUpdated = 'SubscriptionUpdated',
  ChargeFundedCalled = 'ChargeFundedCalled',
  ChargePendingCalled = 'ChargePendingCalled',
  ProcessFundedCalled = 'ProcessFundedCalled',
  ChargeUnpaidCalled = 'ChargeUnpaidCalled',
  CreateOrder = 'CreateOrder',
  CaptureOrder = 'CaptureOrder',
  CancelOrder = 'CancelOrder',
  PaymentMethodChangeFailed = 'PaymentMethodChangeFailed',
  PaymentMethodChanged = 'PaymentMethodChanged',
  AddCreditCalled = 'AddCreditCalled',
  Admin = 'Admin',
  PaymentMethodChangeStarted = 'PaymentMethodChangeStarted',
  WipeBalance = 'WipeBalance',
  SuperchargeSubscriptionCreateStarted = 'SuperchargeSubscriptionCreateStarted',
  DiscountCreated = 'DiscountStarted',
  DiscountDeleted = 'DiscountDeleted',
  AdjustBalance = 'AdjustBalance',
  ChangePaymentDay = 'ChangePaymentDay',
  AdminChangePaymentDay = 'AdminChangePaymentDay',
  AdminChangePaymentDayFailed = 'AdminChangePaymentDayFailed',
  AdminSubscriptionChangeStarted = 'AdminSubscriptionChangeStarted',
  AdminSubscriptionChangeFailed = 'AdminSubscriptionChangeFailed',
}

export enum UserHistoryEventType {
  AdminBlockUser = 'AdminBlockUser',
  AdminChangeEmail = 'AdminChangeEmail',
  AdminUpdateUser = 'AdminUpdateUser',
  AdminUpdateUserGroups = 'AdminUpdateUserGroups',
  AdminListDocuments = 'AdminListDocuments',
  AdminUploadDocument = 'AdminUploadDocument',
  AdminDeleteDocument = 'AdminDeleteDocument',
  AdminUpdateSetting = 'AdminUpdateSetting',
}

export enum ChargeAllType {
  activeOnly = 'activeOnly',
  all = 'all',
  cancelledOnly = 'cancelledOnly',
}

export enum CreditCardProfileStatus {
  new = 'new',
  pending = 'pending',
  active = 'active',
  rejected = 'rejected',
  deleted = 'deleted',
  disabled = 'disabled',
  cancelled = 'cancelled',
}

export type Comment = {
  id: string;
  userId: string;
  userName: string;
  text: string;
  timestamp: string;
  isDraft: boolean;
};
