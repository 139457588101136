import { BooleanInput, DateInput, Edit, required, SelectInput, SimpleForm, TextInput } from 'react-admin';
import { UserTitle } from '../../utils/models';
import { Box } from '@mui/material';
import { CommentsInput } from '../CommentsInput';

export const UserEdit = () => {
  return (
    <Edit mutationMode="pessimistic" redirect="show">
      <SimpleForm>
        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: 'repeat(2, 1fr)',
            gap: 2,
          }}
        >
          <Box display="flex" flexDirection="column" gap={2}>
            <TextInput source="firstName" validate={required()} />
            <TextInput source="lastName" validate={required()} />
            <SelectInput
              source="title"
              choices={Object.values(UserTitle).map((value) => ({
                id: value,
                name: value,
              }))}
              validate={required()}
            />
            <DateInput source="dateBirth" validate={required()} />
            <TextInput source="phoneNumber" validate={required()} />
            <TextInput source="address1" validate={required()} />
            <TextInput source="address2" />
            <TextInput source="address3" />
            <TextInput source="postCode" validate={required()} />
            <TextInput source="city" />
            <TextInput source="emergencyContact" />
            <TextInput source="referredBy" />
            <BooleanInput source="disabledReporting" />
            <TextInput multiline source="notes" />
          </Box>
          <Box display="flex" flexDirection="column" gap={2} sx={{ height: '100%', flexGrow: 1 }}>
            <Box
              sx={{
                flexGrow: 1,
                height: 0,
                width: 540,
                overflowY: 'auto',
                border: '1px solid #ddd',
                padding: 2,
              }}
            >
              <CommentsInput source="comments" />
            </Box>
          </Box>
        </Box>
      </SimpleForm>
    </Edit>
  );
};
