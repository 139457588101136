import { Datagrid, DateField, List, TextField, WithRecord } from 'react-admin';
import { useParams } from 'react-router-dom';

export const KycDetails = () => {
  const { userId } = useParams();

  return (
    <List resource="kycDetails" filter={{ userId }} title={`KYC details for ${userId}`}>
      <Datagrid>
        <DateField source="dateReceived" sortable={false} showTime locales="en-GB" />
        <TextField source="kycStatus" sortable={false} />
        <TextField source="reviewType" sortable={false} />
        <TextField source="rawEvent.type" sortable={false} label="Webhook type" />
        <TextField source="rawEvent.reviewStatus" sortable={false} label="Review status" />
        <TextField source="rawEvent.reviewResult.reviewAnswer" sortable={false} label="Review result" />
        <TextField source="rawEvent.reviewResult.clientComment" sortable={false} label="Client comment" />
        <WithRecord
          label="Reject labels"
          render={(record) => <span>{(record.rawEvent?.reviewResult?.rejectLabels ?? []).join(', ')}</span>}
        ></WithRecord>
        <TextField source="rawEvent.reviewResult.reviewRejectType" sortable={false} label="Reject type" />
      </Datagrid>
    </List>
  );
};
