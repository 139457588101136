import { useRecordContext } from 'react-admin';

export interface CustomUrlFieldProps {
  prefix?: string;
  template?: string;
  source: string;
  target: string;
  rel: string;
  text?: string;
  label?: string;
  sortable?: boolean;
}

export const get = (o: any, path: string) => path.split('.').reduce((o = {}, key) => o[key], o);

const CustomUrlField = (props: CustomUrlFieldProps) => {
  const { source, target, rel, prefix, template } = props;
  const record = useRecordContext(props);
  const value = record && get(record, source);

  if (value == null) {
    return null;
  }

  const href = prefix != null ? `${prefix}${value}` : template != null ? template.replace('<ID>', value) : '';
  return (
    <a href={href} target={target} rel={rel}>
      {props.text ?? value}
    </a>
  );
};

CustomUrlField.defaultProps = {
  addLabel: true,
};

export default CustomUrlField;
