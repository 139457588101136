import IHandler from './IHandler';
import { GetListParams, GetListResult, RaRecord } from 'react-admin';
import { postJson } from '../../../utils/api';

export default class UserSMSNotificationListHandler implements IHandler {
  static prevNextToken: Record<number, string> = {};

  static async getListHandler<RecordType extends RaRecord = any>(
    _?: string,
    params?: GetListParams
  ): Promise<GetListResult<RecordType>> {
    const query: { [key: string]: any } = {
      limit: params?.pagination.perPage ?? 100,
      ...params?.filter,
      userId: params?.meta?.userId ?? '',
    };

    const page = params?.pagination.page ?? 1;
    if (page > 1 && UserSMSNotificationListHandler.prevNextToken[page - 1] != null) {
      query['nextToken'] = UserSMSNotificationListHandler.prevNextToken[page - 1];
    }

    for (const param in query) {
      if (query[param] === undefined || query[param] === null || query[param] === '') {
        delete query[param];
      }
    }

    return postJson(`/admin/notifications/sms/search`, query, process.env.REACT_APP_API_BASE_URL).then(
      async (response) => {
        let { data: responseData } = await response.json();
        const nextToken = responseData?.nextToken;
        UserSMSNotificationListHandler.prevNextToken[page] = JSON.stringify(nextToken);
        responseData = responseData?.items?.map((data: any) => {
          const result = {
            ...data,
          };

          result.id = `${data.userId}-${data.dateCreated}`;
          return {
            ...result,
          };
        });

        return {
          data: responseData,
          pageInfo: {
            hasNextPage: !!nextToken,
            nextPageToken: nextToken ?? undefined,
          },
        };
      }
    );
  }
}
