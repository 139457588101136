import { Create, FileField, FileInput, required, SelectInput, SimpleForm } from 'react-admin';
import { useQuery } from '../../hooks/useQuery';
import { DocumentType } from '../../utils/models';

const CHOICES = Object.values(DocumentType).map((documentType) => ({
  id: documentType,
  name: documentType,
}));

export const DocumentUpload = () => {
  const userId = useQuery().get('userId');

  return (
    <Create mutationOptions={{ meta: { userId } }} redirect={`/users/${userId}/documents`}>
      <SimpleForm>
        <SelectInput source="documentType" label="DocumentType" choices={CHOICES} validate={required()} />
        <FileInput source="file" label="Upload file" validate={required()} multiple={false}>
          <FileField source="src" title="title" />
        </FileInput>
      </SimpleForm>
    </Create>
  );
};
