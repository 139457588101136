import { Datagrid, DateInput, List, Pagination, ReferenceField, TextField, TextInput } from 'react-admin';
import { LinkToDocuments } from '../LinkToDocuments';
import { useRecordContext } from 'ra-core';
import get from 'lodash/get';
import { Link } from 'react-router-dom';
import { useResourceAccess } from '../../hooks/useAccess';

const UserLink = (props: any) => {
  const { source } = props;
  const record = useRecordContext(props);
  const value = get(record, source);

  const userShowPage = `/users/${value}/show`;
  return <Link to={userShowPage}>{value}</Link>;
};

const SubscriptionLink = (props: any) => {
  const { source } = props;
  const record = useRecordContext(props);
  const { id } = record;
  const value = get(record, source);

  const subscriptionShowPage = `/subscriptions/${id}/show`;

  return <Link to={subscriptionShowPage}>{value}</Link>;
};

const PostPagination = () => <Pagination rowsPerPageOptions={[10, 25, 50, 100]} />;

const filters = [
  <TextInput source="userId" alwaysOn name="userId" label="User ID" />,
  <TextInput source="lastName" alwaysOn name="lastName" label="Last Name" helperText={`Search by exact match`} />,
  <TextInput source="firstName" alwaysOn name="firstName" label="First Name" helperText={`Search by exact match`} />,
  <TextInput source="email" alwaysOn name="email" label="Email" />,
  <TextInput source="phoneNumber" alwaysOn name="phoneNumber" label="Phone Number" />,
  <TextInput source="postCode" alwaysOn name="postCode" label="PostCode" />,
  <DateInput source="dateBirth" alwaysOn name="dateBirth" label="DOB" />,
  <TextInput source="name" alwaysOn name="name" label="Name" />,
];

export const UserList = () => {
  const { canViewDocument } = useResourceAccess();

  return (
    <List resource="users" pagination={<PostPagination />} hasCreate={false} exporter={false} filters={filters}>
      <Datagrid>
        <UserLink source="id" name="User" />
        <TextField source="firstName" />
        <TextField source="lastName" />
        <TextField source="email" />
        <TextField source="phoneNumber" />
        <TextField source="status" />
        <TextField source="verificationStatus" label="KYC status" />
        <ReferenceField reference={'subscriptions'} source={'id'} label={'Subscription'}>
          <SubscriptionLink source="status" name="Subscription"></SubscriptionLink>
        </ReferenceField>
        {canViewDocument() && <LinkToDocuments />}
      </Datagrid>
    </List>
  );
};
