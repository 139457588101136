import { Loading, useGetList } from 'react-admin';
import React from 'react';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

export const SubscriptionPlanSelect = ({
  currentPlanStripeId,
  planId,
  onSelect,
}: {
  showOnlyEnabled: boolean;
  currentPlanStripeId: string;
  planId: string | null | undefined;
  onSelect: (value: string) => void;
}) => {
  const { data, isLoading, error } = useGetList('plans', {
    meta: {
      selectAll: true,
    },
  });

  if (isLoading) {
    return <Loading></Loading>;
  }

  if (error) {
    return <div>Failed loading plans.</div>;
  }

  let selectedPlanId = planId;
  if (!selectedPlanId) {
    selectedPlanId = data?.find((d) => d.stripePlanId === currentPlanStripeId)?.id;
  }

  return (
    <Select
      id="planId"
      label="planId"
      fullWidth
      value={selectedPlanId ?? ''}
      onChange={(event: SelectChangeEvent) => onSelect(event.target.value as string)}
    >
      {data?.map((d) => {
        return (
          <MenuItem key={d.id} value={d.id} disabled={d.stripePlanId === currentPlanStripeId}>
            {d.name}
          </MenuItem>
        );
      })}
    </Select>
  );
};
