import * as React from 'react';
import { Grid } from '@mui/material';
import { Chart } from './Chart';
import { ChartConstructorType, useAnalytics } from '../../hooks/useAnalytics';

export const Analytics = () => {
  const { charts } = useAnalytics();
  return (
    <Grid container spacing={1} mt={1}>
      {charts.map((chartOptions, index) => (
        <Chart options={chartOptions} constructorType={ChartConstructorType.STOCK} key={`chart-${index}`} />
      ))}
    </Grid>
  );
};
