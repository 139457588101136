import { AuthProvider } from 'react-admin';
import { Access } from '../../utils/access';

export const createLocalAuthProvider = (): AuthProvider => {
  return {
    login: () => {
      return Promise.resolve(true);
    },
    logout: () => {
      return Promise.resolve();
    },
    checkAuth: () => {
      return Promise.resolve();
    },
    async getPermissions() {
      return Access(['super-admin']);
    },
    async checkError() {},
  };
};
