import IHandler from './IHandler';
import { GetOneParams, GetOneResult, RaRecord } from 'react-admin';
import { getJson } from '../../../utils/api';

/**
 * We are using React Admin. When it fetches data in components like Show,
 * it relies on the id's existence in every record it fetches.
 * In the case of a User record, we may consider `membershipNumber` or `userId` fields as `id`.
 * This class sets `userId` as `id`, which differs from {@link UserListHandler} that sets `membershipNumber` as `id`.
 */
export default class UserHandler implements IHandler {
  static prevNextToken: Record<number, string> = {};
  static route = '/admin/users';
  static resourceIdName = 'userId';

  static async getOneHandler<RecordType extends RaRecord = any>(
    _: string,
    params?: GetOneParams
  ): Promise<GetOneResult<RecordType>> {
    return getJson(`${this.route}/${params?.id}`, process.env.REACT_APP_API_BASE_URL).then(async (response) => {
      const { data } = await response.json();

      data.id = data[this.resourceIdName];

      return {
        data,
      };
    });
  }
}
