import { AUTH_TOKEN_KEY } from '../hooks/useAuthentication';
import { getCognitoAccessToken } from '../react-admin/providers/cognitoAuthProvider';
import { Config } from '../react-admin/providers/config';

const getCurrentAccessToken = async (): Promise<string | null> => {
  let token = await getCognitoAccessToken();
  if (!token) {
    token = localStorage.getItem(AUTH_TOKEN_KEY);
  }

  return token ?? null;
};

export async function fetchApi(
  url: string,
  init: RequestInit,
  apiBaseUrl = process.env.REACT_APP_API_BASE_URL ?? 'api/v1/'
): Promise<Response> {
  const usedLocalToken = Config.useLocalAuthProvider && Config.localToken ? Config.localToken : null;
  const token = usedLocalToken ?? (await getCurrentAccessToken());
  if (token && token !== '') {
    if (init && init.headers) init.headers = { ...init?.headers, Authorization: token };
    else if (init)
      init.headers = {
        Authorization: token,
        'Content-Type': 'application/json',
      };
  }
  try {
    const response = await fetchWithTimeout(`${apiBaseUrl}${url}`, init);
    if (response.status >= 200 && response.status < 300) {
      return response;
    }
    const responseBodyMethod = (response.headers.get('content-type') || '').includes('json') ? 'json' : 'text';

    return Promise.reject({
      status: response.status,
      error: (await response[responseBodyMethod]()) || response.statusText,
      requestMethod: init?.method || 'GET',
      message: response.statusText,
    });
  } catch (e: any) {
    if (e.message === 'Failed to fetch') {
      console.warn(
        `Most probably this endpoint ${apiBaseUrl}${url} has incorrect cors settings for error response. Error: ${JSON.stringify(
          e,
          null,
          2
        )}`
      );
    }
    return Promise.reject({
      status: 500,
      error: 'InternalError',
      requestMethod: init?.method || 'GET',
      message: e.message,
    });
  }
}

export async function fetchWithTimeout(
  url: string,
  options: Parameters<typeof fetch>[1],
  timeout = 30000
): Promise<Response> {
  return Promise.race([
    fetch(url, options),
    new Promise((_, reject) => setTimeout(() => reject(new Error('Request timed out')), timeout)) as any as Response,
  ]);
}

export async function postJson<T>(url: string, data: T, apiBaseUrl?: string): Promise<Response> {
  return fetchApi(
    url,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify(data),
    },
    apiBaseUrl
  );
}

export async function putJson<T>(url: string, data: T, apiBaseUrl?: string): Promise<Response> {
  return fetchApi(
    url,
    {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify(data),
    },
    apiBaseUrl
  );
}

export async function deleteJson<T>(url: string, data: T, apiBaseUrl?: string): Promise<Response> {
  return fetchApi(
    url,
    {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify(data),
    },
    apiBaseUrl
  );
}

export async function getJson(url: string, apiBaseUrl?: string): Promise<Response> {
  return fetchApi(
    url,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    },
    apiBaseUrl
  );
}

export async function postForm(url: string, data: FormData, apiBaseUrl?: string): Promise<Response> {
  return fetchApi(
    url,
    {
      method: 'POST',
      headers: {},
      body: data,
    },
    apiBaseUrl
  );
}

export const processApiError = (error: any) => {
  let message = 'Internal server error';
  if (error.error != null) {
    message = `${error.error.errorType}: ${error.error.errorMessage}`;
  }
  if (error.error?.details != null) {
    message = `${error.error.errorMessage}: ${error.error?.details.map((d: any) => d.text).join(', ')}`;
  }
  return Promise.reject({ message });
};
