import {
  Button,
  Datagrid,
  DateField,
  Link,
  List,
  ReferenceField,
  required,
  SelectInput,
  useListContext,
  useRecordContext,
  useRefresh,
} from 'react-admin';
import get from 'lodash/get';
import DataMismatchUserHandler from '../../react-admin/providers/handlers/DataMismatchUserHandler';
import { useResourceAccess } from '../../hooks/useAccess';

const UserLink = (props: any) => {
  const { source } = props;
  const record = useRecordContext(props);
  const value = get(record, source);

  const userShowPage = `/users/${value}/show`;
  return <Link to={userShowPage}>{value}</Link>;
};

const SumsubLink = (props: any) => {
  const { source } = props;
  const record = useRecordContext(props);
  const value = get(record, source);

  const inspectionLink = `https://cockpit.sumsub.com/checkus#/applicants/individual?limit=10&page=0&searchQuery=${value}`;
  return (
    <a href={inspectionLink} target="_blank">
      {inspectionLink}
    </a>
  );
};

const useDataMismatchUserActions = () => {
  const refresh = useRefresh();
  const review = async (userIds: string[]) => {
    await DataMismatchUserHandler.review(userIds);
    refresh();
  };

  return {
    review,
  };
};

const DataMismatchUserListActions = () => {
  const { selectedIds } = useListContext();
  const { review } = useDataMismatchUserActions();
  const { canEdit } = useResourceAccess();
  if (!canEdit()) {
    return <></>;
  }
  return (
    <>
      <Button label="Mark as reviewed" onClick={() => review(selectedIds)}></Button>
    </>
  );
};

const filters = [
  <SelectInput
    source="reviewed"
    choices={[
      { id: 'true', name: 'Reviewed' },
      { id: 'false', name: 'Not reviewed' },
    ]}
    defaultValue={'false'}
    validate={required()}
  />,
];

export const DataMismatchUserList = () => {
  return (
    <List resource="kycmismatch" title="KYC fullname mismatch" filters={filters}>
      <Datagrid bulkActionButtons={<DataMismatchUserListActions />}>
        <UserLink source="id" label={'User'} />
        <SumsubLink source="id" label="Applicant url" />
        <ReferenceField reference={'users'} source={'reviewedBy'} label={'Reviewed By'} />
        <DateField source="reviewedDate" />
      </Datagrid>
    </List>
  );
};
