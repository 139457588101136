import { Card, CardContent, Grid } from '@mui/material';
import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import { ChartConstructorType } from '../../hooks/useAnalytics';

export const Chart = ({
  options,
  constructorType = ChartConstructorType.CHART,
}: {
  options: any;
  constructorType?: ChartConstructorType;
}) => {
  return (
    <Grid item lg={4} md={6} sm={12}>
      <Card>
        <CardContent>
          <HighchartsReact highcharts={Highcharts} options={options} constructorType={constructorType} />
        </CardContent>
      </Card>
    </Grid>
  );
};
