import {
  ChipField,
  Datagrid,
  DateField,
  Empty,
  List,
  NumberField,
  Pagination,
  TextField,
  useRecordContext,
} from 'react-admin';
import { useParams } from 'react-router-dom';
import * as React from 'react';

const PostPagination = () => <Pagination rowsPerPageOptions={[10, 25, 50, 100]} />;

export const PushNotificationList = () => {
  const params = useParams();
  const record = useRecordContext();

  const userId = params.userId ?? record?.id;
  if (!userId) {
    return <Empty />;
  }

  return (
    <List
      resource={'pushnotifications'}
      queryOptions={{ meta: { userId } }}
      hasCreate={false}
      hasEdit={false}
      hasShow={false}
      perPage={100}
      pagination={<PostPagination />}
    >
      <Datagrid bulkActionButtons={false}>
        <DateField source="dateCreated" />
        <NumberField source="sendingDelay" />
        <NumberField source="deviceCount" />
        <TextField source="title" label="Message" />
        <ChipField source="status" />
        <TextField source="error" />
      </Datagrid>
    </List>
  );
};
