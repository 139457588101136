import { getJson } from './api';

export interface StartKycSessionResponse {
  isKycRequired: boolean;
  accessToken?: string;
}

export enum UserKycStatus {
  kycSubmitted = 'kyc-submitted',
  kycApproved = 'kyc-approved',
  kycError = 'kyc-error',
  kycDeclined = 'kyc-declined',
  kycMissingData = 'kyc-missing-data',
}

export enum UserInitialKycStatus {
  initialized = 'initialized',
  created = 'created',
  pending = 'pending',
  retryRequired = 'retry-required',
  approved = 'approved',
  denied = 'denied',
}

export interface AmlInspectionDetails {
  applicant: any;
  inspection: any;
}

export interface KycUserProps {
  initialKycStatus?: UserInitialKycStatus;
  amlStatus?: UserInitialKycStatus;
  kycStatus?: UserKycStatus;
  lastAMLCheck?: string;
  lastFullKYCCheck?: string;
  dateCreate?: string;
  dateUpdate?: string;
  error?: string;
  tag?: string;
  amlInspectionDetails?: AmlInspectionDetails;
}

export type SumSubWebhookType =
  | 'applicantReviewed'
  | 'applicantPending'
  | 'applicantCreated'
  | 'applicantOnHold'
  | 'applicantPersonalInfoChanged'
  | 'applicantPrechecked'
  | 'applicantDeleted'
  | 'videoIdentStatusChanged'
  | 'applicantReset'
  | 'applicantActionPending'
  | 'applicantActionReviewed'
  | 'applicantActionOnHold'
  | 'applicantTravelRuleStatusChanged';

export type SumSubReviewStatus = 'init' | 'pending' | 'prechecked' | 'queued' | 'completed' | 'onHold';

export enum SumSubRejectType {
  FINAL = 'FINAL',
  RETRY = 'RETRY',
}

export enum SumSubReviewAnswer {
  RED = 'RED',
  GREEN = 'GREEN',
}

export interface SumSubReviewResult {
  reviewAnswer: SumSubReviewAnswer;
  moderationComment?: string;
  clientComment?: string;
  rejectLabels?: string[];
  reviewRejectType?: SumSubRejectType;
}

export interface SumSubWebhookBody {
  applicantId: string;
  inspectionId: string;
  correlationId: string;
  externalUserId: string;
  type: SumSubWebhookType;
  createdAt: string;
  reviewStatus: SumSubReviewStatus;
  reviewResult?: SumSubReviewResult;
  sandboxMode: boolean;
  clientId: string;
  levelName?: string;
  applicantType?: string;
  applicantMemberOf?: any[];
  videoIdentReviewStatus?: string;
  applicantActionId?: string;
  externalApplicantActionId?: string;
  travelRuleRequestId?: string;
}

export interface HistoryItem {
  dateReceived: string;
  eventId: string;
  kycStatus: string;
  providerId: string;
  reviewType: string;
  userId: string;

  rawEvent: SumSubWebhookBody;
}

export class KycApi {
  async getStatus(userId: string): Promise<KycUserProps> {
    return await getJson(`/status/${userId}`, process.env.REACT_APP_BASE_KYC_API_URL)
      .then((response) => response.json())
      .then((response) => response['data']);
  }
}
