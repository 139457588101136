import {
  Button,
  Datagrid,
  DateField,
  Link,
  List,
  ReferenceField,
  required,
  SelectInput,
  TextField,
  useListContext,
  useRecordContext,
  useRefresh,
} from 'react-admin';
import get from 'lodash/get';
import CardVerificationHandler from '../../react-admin/providers/handlers/CardVerificationHandler';
import { useResourceAccess } from '../../hooks/useAccess';
import { StripeLink } from '../StripeLink';

const UserLink = (props: any) => {
  const { source } = props;
  const record = useRecordContext(props);
  const value = get(record, source);

  const userShowPage = `/users/${value}/show`;
  return <Link to={userShowPage}>{value}</Link>;
};

const SubscriptionLink = (props: any) => {
  const record = useRecordContext(props);
  const { id } = record;

  const subscriptionShowPage = `/subscriptions/${id}/show`;

  return (
    <Link to={subscriptionShowPage}>
      <ReferenceField reference={'subscriptions'} source={'id'}>
        <TextField source="status" />
      </ReferenceField>
    </Link>
  );
};

const useCardVerificationActions = () => {
  const refresh = useRefresh();
  const rejectCardVerification = async (userIds: string[]) => {
    await CardVerificationHandler.rejectCardVerification(userIds);
    refresh();
  };
  const approveCardVerification = async (userIds: string[]) => {
    await CardVerificationHandler.approveCardVerification(userIds);
    refresh();
  };

  return {
    rejectCardVerification,
    approveCardVerification,
  };
};

const CardVerificationListActions = () => {
  const { selectedIds } = useListContext();
  const { rejectCardVerification, approveCardVerification } = useCardVerificationActions();
  const { canEdit } = useResourceAccess();
  if (!canEdit()) {
    return <></>;
  }
  return (
    <>
      <Button label="Approve" onClick={() => approveCardVerification(selectedIds)}></Button>
      <Button label="Reject" onClick={() => rejectCardVerification(selectedIds)}></Button>
    </>
  );
};

const filters = [
  <SelectInput
    source="cardVerified"
    choices={[
      { id: 'true', name: 'Verified' },
      { id: 'false', name: 'Not verified' },
      { id: 'rejected', name: 'Rejected' },
    ]}
    defaultValue={'false'}
    validate={required()}
  />,
];

export const CardVerificationList = () => {
  return (
    <List resource="cardverification" title="Card Name Verification" filters={filters}>
      <Datagrid bulkActionButtons={<CardVerificationListActions />}>
        <UserLink source="id" label={'User'} />
        <SubscriptionLink source="id" label={'Subscription'} />
        <TextField source="verified" label="Verification status" />
        <TextField source="paymentMethod.name" label="Name in card" />
        <TextField source="paymentMethod.fullName" label="Full Name" />
        <StripeLink source="paymentMethod.id" label="Stripe" />
        <DateField source="dateUpdate" />
      </Datagrid>
    </List>
  );
};
