import { Dialog, DialogTitle, DialogContent, DialogActions, TextField, Grid, Button } from '@mui/material';
import IconCancel from '@mui/icons-material/Cancel';
import IconSave from '@mui/icons-material/Save';
import { useEffect, useState } from 'react';
import { useGetOne, useNotify, useRefresh } from 'react-admin';
import SubscriptionHandler from '../../react-admin/providers/handlers/SubscriptionHandler';
import CreditCardProfileHandler from '../../react-admin/providers/handlers/CreditCardProfileHandler';
import { CreditCardProfileStatus } from '../../utils/models';

const useCancelSubscription = ({
  setShow,
  subscriptionId,
}: {
  setShow: (flag: boolean) => void;
  subscriptionId: string | undefined;
}) => {
  const {
    data: creditCardProfile,
    isLoading: creditCardIsLoading,
    isError: creditCardIsError,
  } = useGetOne(`creditcardprofile`, { id: subscriptionId });
  const [creditCardActive, setCreditCardActive] = useState(true);
  const [reason, setReason] = useState('');
  const [loading, setLoading] = useState(false);
  const notify = useNotify();
  const refresh = useRefresh();

  useEffect(() => {
    if (creditCardIsLoading) {
      setLoading(true);
      setCreditCardActive(true);
    } else {
      setLoading(false);
    }
  }, [creditCardIsLoading]);

  useEffect(() => {
    if (creditCardIsLoading) {
      return;
    }

    if (creditCardIsError) {
      setCreditCardActive(false);
      setLoading(false);
      return;
    }

    if (
      [CreditCardProfileStatus.active, CreditCardProfileStatus.new, CreditCardProfileStatus.pending].includes(
        creditCardProfile.status
      )
    ) {
      setCreditCardActive(true);
    } else {
      setCreditCardActive(false);
    }
  }, [creditCardIsError, creditCardProfile]);

  const close = () => {
    setLoading(false);
    setReason('');
    setShow(false);
  };
  const cancelSubscription = async () => {
    setLoading(true);
    if (reason !== '' && subscriptionId !== undefined) {
      if (creditCardActive) {
        await CreditCardProfileHandler.deactivateSubscription(
          subscriptionId,
          reason,
          () => {},
          (e: any) => {
            notify(`Failed to cancel credit card`, { type: 'error' });
            notify(e.toString(), { type: 'error' });
          }
        );
      }

      await SubscriptionHandler.cancelSubscription(
        subscriptionId,
        reason,
        () => {
          refresh();
          close();
        },
        (e: any) => {
          notify(`Failed to cancel subscription`, { type: 'error' });
          notify(e.toString(), { type: 'error' });
          close();
        }
      );
    } else {
      close();
    }
  };

  return {
    reason,
    setReason: (event: any) => setReason(event.target.value),
    cancelSubscription,
    close,
    loading,
    creditCardActive,
  };
};

export const CancelSubscriptionAction = ({ wallet }: { wallet: any }) => {
  const [show, setShow] = useState(false);
  const { reason, setReason, cancelSubscription, close, loading, creditCardActive } = useCancelSubscription({
    setShow,
    subscriptionId: wallet.id,
  });

  return (
    <>
      <Button color="primary" onClick={() => setShow(true)}>
        Cancel
      </Button>
      <Dialog fullWidth open={show} onClose={setShow} aria-label="Cancel Subscription">
        <DialogTitle>Cancel subscription</DialogTitle>
        <DialogContent>
          <Grid item xs={12}>
            {!loading && creditCardActive && (
              <span>
                <b>Attention!</b>
                <br />
                <br />
                You are about to cancel customer's credit card.
                <br />
                <br />
                Are you sure you want to proceed?
              </span>
            )}
            <TextField
              id="reason"
              placeholder="Reason"
              variant="outlined"
              fullWidth
              value={reason}
              onChange={setReason}
            />
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={close} disabled={loading}>
            <IconCancel />
            <span>Close</span>
          </Button>
          <Button onClick={cancelSubscription} disabled={reason === '' || loading}>
            <IconSave />
            <span>Save</span>
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
