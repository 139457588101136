import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField } from '@mui/material';
import IconCancel from '@mui/icons-material/Cancel';
import IconSave from '@mui/icons-material/Save';
import { useState } from 'react';
import { useNotify, useRefresh } from 'react-admin';
import { useRecordContext } from 'ra-core';
import { KycUserProps, UserInitialKycStatus, UserKycStatus } from '../../utils/KycApi';
import CheckIcon from '@mui/icons-material/Check';
import KycListHandler from '../../react-admin/providers/handlers/KycListHandler';

const useKycApprove = ({
  setShow,
  userId,
  type,
}: {
  setShow: (flag: boolean) => void;
  userId: string | undefined;
  type: 'kyc' | 'aml';
}) => {
  const [loading, setLoading] = useState(false);
  const [reason, setReason] = useState('');
  const notify = useNotify();

  const refresh = useRefresh();
  const close = () => {
    setLoading(false);
    setShow(false);
  };
  const approveUser = async () => {
    setLoading(true);
    if (reason !== '' && userId != null) {
      KycListHandler.approve(
        userId,
        type,
        reason,
        () => {
          refresh();
          close();
        },
        (e: any) => {
          notify(e.toString(), { type: 'error' });
          close();
        }
      );
    } else {
      close();
    }
  };

  return {
    reason,
    setReason: (event: any) => setReason(event.target.value),
    approveUser,
    close,
    loading,
  };
};

export const KycApproveAction = ({ type }: { type: 'kyc' | 'aml' }) => {
  const user = useRecordContext() as KycUserProps & { id: string };
  const [show, setShow] = useState(false);
  const { reason, setReason, approveUser, close, loading } = useKycApprove({
    type,
    setShow,
    userId: user?.id as string,
  });

  if (
    user == null ||
    (type === 'kyc' && user.kycStatus === UserKycStatus.kycApproved) ||
    (type === 'aml' && user.amlStatus === UserInitialKycStatus.approved)
  ) {
    return <></>;
  }

  return (
    <>
      <Button color="primary" onClick={() => setShow(true)}>
        <CheckIcon /> Approve {type.toUpperCase()}
      </Button>
      <Dialog fullWidth open={show} onClose={setShow} aria-label="Block User">
        <DialogTitle>{type.toUpperCase()} approve</DialogTitle>
        <DialogContent>
          <Grid item xs={12}>
            Are you sure you want to approve the user?
            <TextField
              id="reason"
              placeholder="Reason"
              variant="outlined"
              fullWidth
              value={reason}
              onChange={setReason}
            />
          </Grid>
        </DialogContent>

        <DialogActions>
          <Button onClick={close} disabled={loading}>
            <IconCancel />
            <span>Close</span>
          </Button>
          <Button onClick={approveUser} disabled={reason === '' || loading}>
            <IconSave />
            <span>Save</span>
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
