import {
  CreateButton,
  Datagrid,
  DeleteWithConfirmButton,
  List,
  Loading,
  Pagination,
  TextField,
  TopToolbar,
  useRecordContext,
} from 'react-admin';
import { useParams } from 'react-router-dom';
import get from 'lodash/get';
import { Link, Typography } from '@mui/material';

const DocumentLink = (props: any) => {
  const { source, name } = props;
  const record = useRecordContext(props);
  const value = get(record, source);

  if (value == null) {
    return <Typography component="span" variant="body2"></Typography>;
  }

  return (
    <Link href={value} variant="body2">
      {name ?? value}
    </Link>
  );
};

const DocumentCreateButton = () => {
  const { userId } = useParams();

  return <CreateButton label="Upload" to={`/documents/create?userId=${userId}`} />;
};

const DocumentListActions = () => {
  return (
    <TopToolbar>
      <DocumentCreateButton />
    </TopToolbar>
  );
};

const PostPagination = () => <Pagination rowsPerPageOptions={[10, 25, 50, 100]} />;
export const DocumentList = () => {
  const { userId } = useParams();
  if (!userId) {
    return <Loading />;
  }

  return (
    <List
      resource="documents"
      queryOptions={{ meta: { userId } }}
      pagination={<PostPagination />}
      hasCreate={true}
      exporter={false}
      actions={<DocumentListActions />}
    >
      <Datagrid>
        <TextField source="documentId" />
        <TextField source="name" />
        <DocumentLink source="url" name="link" />
        <DeleteWithConfirmButton
          mutationOptions={{ meta: { userId } }}
          redirect={`/users/${userId}/documents`}
          confirmContent="You will not be able to recover this record. Are you sure?"
        />
      </Datagrid>
    </List>
  );
};
