import { createDateRenderer } from '../../utils/misc';

import {
  Datagrid,
  FunctionField,
  List,
  Pagination,
  ReferenceField,
  SelectArrayInput,
  Show,
  Tab,
  TabbedShowLayout,
  TextField,
  ReferenceInput,
  AutocompleteInput,
  DateInput,
  useAuthProvider,
} from 'react-admin';
import { SubscriptionHistoryEventType, UserHistoryEventType } from '../../utils/models';
import { useGetUserId } from '../../react-admin/providers/cognitoAuthProvider';

const PostPagination = () => <Pagination rowsPerPageOptions={[10, 25, 50, 100]} perPage={100} />;

const getFilters = (eventTypes: any) => {
  return [
    <SelectArrayInput
      source="eventTypes"
      choices={Object.values(eventTypes).map((value) => ({ id: value, name: value }))}
      alwaysOn
    />,
    <ReferenceInput source="userId" reference="users" label="ChangedBy" alwaysOn>
      <AutocompleteInput filterToQuery={(t: string) => ({ email: t })} optionText={'email'} />
    </ReferenceInput>,
    <DateInput source="from" label="After" alwaysOn />,
    <DateInput source="to" label="Before" alwaysOn />,
  ];
};

export const SubscriptionHistoryList = () => {
  return (
    <List
      resource={'subscriptionhistory'}
      actions={false}
      filters={getFilters(SubscriptionHistoryEventType)}
      pagination={<PostPagination />}
      queryOptions={{
        refetchInterval: false,
        meta: {},
      }}
    >
      <Datagrid bulkActionButtons={false}>
        <FunctionField source="timestamp" render={createDateRenderer('timestamp')} label={'Created'} />
        <TextField source="eventMessage" />
        <ReferenceField reference={'users'} source={'userId'} label={'User'}>
          <TextField source="email" />
        </ReferenceField>
        <ReferenceField reference={'users'} source={'initiator'} label={'ChangedBy'}>
          <TextField source="email" />
        </ReferenceField>
      </Datagrid>
    </List>
  );
};

export const UserHistoryList = () => {
  return (
    <List
      resource={'userhistory'}
      actions={false}
      filters={getFilters(UserHistoryEventType)}
      pagination={<PostPagination />}
      queryOptions={{
        refetchInterval: false,
        meta: {},
      }}
    >
      <Datagrid bulkActionButtons={false}>
        <FunctionField source="timestamp" render={createDateRenderer('timestamp')} label={'Created'} />
        <TextField source="eventMessage" />
        <ReferenceField reference={'users'} source={'userId'} label={'User'}>
          <TextField source="email" />
        </ReferenceField>
        <ReferenceField reference={'users'} source={'initiator'} label={'ChangedBy'}>
          <TextField source="email" />
        </ReferenceField>
      </Datagrid>
    </List>
  );
};

export const HistoryList = () => {
  const userId = useGetUserId();
  const authProvider = useAuthProvider();

  if (!userId) {
    authProvider.checkAuth({});
    return <></>;
  }

  return (
    <Show resource={'user'} id={userId} actions={false} title={'History'}>
      <TabbedShowLayout>
        <Tab label="Subscriptions">
          <SubscriptionHistoryList />
        </Tab>
        <Tab label="Users">
          <UserHistoryList />
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};
