import { Config } from './config';
import { createLocalAuthProvider } from './localAuthProvider';
import { createCognitoAuthProvider } from './cognitoAuthProvider';

export const createAuthProvider = () => {
  if (Config.useLocalAuthProvider) {
    return createLocalAuthProvider();
  }

  return createCognitoAuthProvider();
};
