import CustomUrlField, { CustomUrlFieldProps } from '../../../react-admin/components/CustomUrlField';
import { useRecordContext } from 'ra-core';

/**
 * Example usage:
 * <ConditionalCustomUrlField source="gateway.subscription" target="_blank" rel="noopener noreferrer" prefix="https://dashboard.stripe.com/subscriptions/"/>
 */

const getPath = (record: any, path: string) => {
  const keys = path.split('.');
  let result = record;
  for (const key of keys) {
    if (!result) {
      return undefined;
    }
    result = result[key];
  }
  return result;
};

export const ConditionalCustomUrlField = (props: CustomUrlFieldProps) => {
  const record = useRecordContext(props);
  const value = getPath(record, props.source);

  return record && value ? <CustomUrlField {...props} /> : null;
};
