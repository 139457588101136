import IHandler from './IHandler';
import { GetListParams, GetListResult, RaRecord } from 'react-admin';
import { getJson } from '../../../utils/api';
import { HistoryItem } from '../../../utils/KycApi';

export default class KycDetailsHandler implements IHandler {
  static route = '/admin/users';
  static resourceIdName = 'userId';

  static async getListHandler<RecordType extends RaRecord = any>(
    resource?: string,
    params?: GetListParams
  ): Promise<GetListResult<RecordType>> {
    const { userId } = (params ?? {}).filter;

    return getJson(`${this.route}/${userId}/history`, process.env.REACT_APP_BASE_KYC_API_URL)
      .then(async (response) => {
        let { data: responseData } = await response.json();

        responseData = responseData?.items?.map((data: any) => {
          const result = {
            ...data,
          };

          result.id = data[this.resourceIdName];
          delete result[this.resourceIdName];
          return {
            ...result,
          };
        });
        responseData.sort((a: HistoryItem, b: HistoryItem) => a.dateReceived.localeCompare(b.dateReceived));
        return {
          data: responseData,
          pageInfo: {
            hasNextPage: false,
          },
        };
      })
      .catch((response) => {
        if (response.status === 400) {
          return {
            data: [],
            total: 0,
          };
        }
        return Promise.reject({
          status: response.status,
          error: response.statusText,
          message: response.statusText,
        });
      });
  }
}
