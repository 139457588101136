import { useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import IconCancel from '@mui/icons-material/Cancel';
import IconSave from '@mui/icons-material/Save';
import { useNotify } from 'react-admin';
import SubscriptionHandler from '../../react-admin/providers/handlers/SubscriptionHandler';
import { ChargeAllType } from '../../utils/models';
import dayjs from 'dayjs';

const useChargeAll = () => {
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [chargeType, setChargeType] = useState<string>(ChargeAllType.activeOnly);
  const [dateFrom, setDateFrom] = useState<string>('');
  const notify = useNotify();
  const close = () => {
    setLoading(false);
    setShow(false);
  };

  const chargeAll = async () => {
    setLoading(true);
    SubscriptionHandler.chargeAll(
      {
        type: chargeType as ChargeAllType,
        from: chargeType === ChargeAllType.activeOnly ? undefined : dateFrom ?? undefined,
      },
      () => {
        notify('Started the process of charging outstanding payments.');
        close();
      },
      (e: any) => {
        notify(e.toString(), { type: 'error' });
        close();
      }
    );
  };

  return {
    chargeAll,
    loading,
    setLoading,
    show,
    setShow,
    close,
    chargeType,
    setChargeType: (event: any) => setChargeType(event.target.value),
    dateFrom,
    setDateFrom: (event: any) => setDateFrom(event.target.value ?? null),
  };
};

export const ChargeAll = () => {
  const { show, setShow, loading, chargeAll, close, chargeType, setChargeType, dateFrom, setDateFrom } = useChargeAll();

  const openDialog = () => {
    setShow(true);
    setChargeType(ChargeAllType.activeOnly);
    setDateFrom('');
  };

  return (
    <>
      <Button color="primary" onClick={() => openDialog()}>
        Charge All
      </Button>
      <Dialog fullWidth open={show} onClose={setShow} aria-label="Charge all">
        <DialogTitle>Charge All</DialogTitle>
        <DialogContent>
          <Grid item xs={12}>
            <span>This action attempts to charge all outstanding subscription and store payments.</span>
            {chargeType !== ChargeAllType.activeOnly && (
              <span>
                <br />
                <br />
                <span>
                  You have the option to choose a time period for outstanding payments. The maximum duration allowed is
                  one year.
                </span>
                <br />
              </span>
            )}
            <Select id="chargeType" variant="outlined" value={chargeType} fullWidth onChange={setChargeType}>
              <MenuItem key={ChargeAllType.all} value={ChargeAllType.all}>
                {'All'}
              </MenuItem>
              <MenuItem key={ChargeAllType.activeOnly} value={ChargeAllType.activeOnly}>
                {'Only Active Subscriptions'}
              </MenuItem>
              <MenuItem key={ChargeAllType.cancelledOnly} value={ChargeAllType.cancelledOnly}>
                {'Only Cancelled Subscriptions'}
              </MenuItem>
            </Select>
            {chargeType !== ChargeAllType.activeOnly && (
              <TextField
                id="dateFrom"
                type="date"
                placeholder="Date From"
                variant="outlined"
                fullWidth
                value={dateFrom}
                onChange={setDateFrom}
                inputProps={{
                  min: dayjs().subtract(1, 'year').format('YYYY-MM-DD'),
                  max: dayjs().subtract(1, 'month').format('YYYY-MM-DD'),
                }}
              />
            )}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={close} disabled={loading}>
            <IconCancel />
            <span>Cancel</span>
          </Button>
          <Button onClick={chargeAll} disabled={loading}>
            <IconSave />
            <span>Charge</span>
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
