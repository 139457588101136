import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import IconCancel from '@mui/icons-material/Cancel';
import { useState } from 'react';
import { CheckboxGroupInput, Edit, Form, SaveButton, useGetList, useNotify, useRefresh, useUpdate } from 'react-admin';
import FormHelperText from '@mui/material/FormHelperText';
import { getRoleDescription } from '../../utils/access';

const useChangeGroup = ({ userId }: { userId: string | undefined }) => {
  const [show, setShow] = useState(false);
  const notify = useNotify();
  const [update, { isLoading }] = useUpdate();

  const refresh = useRefresh();
  const close = () => {
    setShow(false);
  };
  const changeGroup = async (data: any) => {
    console.log(data);
    if (userId != null) {
      const { groups } = data;
      update('groups', {
        id: userId,
        data: groups,
      })
        .then(() => {
          refresh();
          close();
        })
        .catch((e: any) => {
          notify(e.toString(), { type: 'error' });
          close();
        });
    } else {
      close();
    }
  };

  return {
    changeGroup,
    close,
    isLoading,
    show,
    setShow,
  };
};

export const ChangeGroupAction = ({ user }: { user: any }) => {
  const { data, isLoading, isError } = useGetList('groups', {
    pagination: {
      perPage: 100,
      page: 0,
    },
  });
  const { changeGroup, setShow, show, close } = useChangeGroup({
    userId: user.id ?? null,
  });

  if (isError || isLoading) {
    return <></>;
  }

  const { id } = user;

  const optionRenderer = (choice: any) => {
    const description = getRoleDescription(choice.name);
    return (
      <>
        {choice.name}
        {description.length > 0 && (
          <FormHelperText>
            <ul>
              {description.map((d: string) => (
                <li>{d}</li>
              ))}
            </ul>
          </FormHelperText>
        )}
      </>
    );
  };

  return (
    <>
      <Button color="primary" onClick={() => setShow(true)} disabled={isLoading || !data}>
        Change Role
      </Button>
      <Dialog fullWidth open={show} onClose={setShow} aria-label="Roles">
        <Edit resource="groups" id={id}>
          <Form onSubmit={changeGroup}>
            <DialogTitle>Change Role</DialogTitle>
            <DialogContent>
              <CheckboxGroupInput
                key={'groups'}
                source="groups"
                label="Roles"
                placeholder="roles"
                choices={(data ?? [])
                  .sort((a: any, b: any) => b.id.localeCompare(a.id))
                  .map((d: any) => {
                    return {
                      id: d.id,
                      name: d.id,
                    };
                  })}
                row={false}
                optionText={optionRenderer}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={close} disabled={isLoading}>
                <IconCancel />
                <span>Close</span>
              </Button>
              <SaveButton />
            </DialogActions>
          </Form>
        </Edit>
      </Dialog>
    </>
  );
};
