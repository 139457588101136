import Highcharts from 'highcharts/highcharts';

interface ChartData {
  data: number[];
}

export interface ChartOptions {
  title: {
    text: string;
  };
  series: ChartData[];
}

export enum ChartConstructorType {
  CHART = 'chart',
  MAP = 'mapChart',
  STOCK = 'stockChart',
  GANTT = 'ganttChart',
}

const rangeSelector = {
  rangeSelector: {
    buttons: [
      {
        type: 'week',
        count: 1,
        text: '1w',
      },
      {
        type: 'month',
        count: 1,
        text: '1m',
      },
      {
        type: 'month',
        count: 3,
        text: '3m',
      },
      {
        type: 'ytd',
        text: 'YTD',
      },
      {
        type: 'year',
        count: 1,
        text: '1y',
      },
      {
        type: 'all',
        text: 'All',
      },
    ],
  },
};

export const useAnalytics = () => {
  // FIXME: REPLACE EVERYTHING BELOW WHEN CONNECTING TO REAL DATA */
  const categories: string[] = [];

  return {
    charts: [
      {
        ...rangeSelector,
        title: {
          text: 'Number of users',
        },
        navigator: {
          enabled: false,
        },
        scrollbar: {
          enabled: false,
        },

        series: [
          {
            name: 'Users',
            data: [],
            tooltip: {
              valueDecimals: 2,
            },
          },
        ],
      },
      {
        navigator: {
          enabled: false,
        },
        scrollbar: {
          enabled: false,
        },
        ...rangeSelector,
        title: {
          text: 'Number of subscribers',
        },

        series: [
          {
            name: 'Subscribers',
            data: [],
            tooltip: {
              valueDecimals: 2,
            },
          },
        ],
      },
      {
        navigator: {
          enabled: false,
        },
        scrollbar: {
          enabled: false,
        },
        ...rangeSelector,
        title: {
          text: 'Average subscription fee',
        },
        series: [
          {
            name: 'Subscription Fee',
            data: [],
            tooltip: {
              valueDecimals: 2,
            },
          },
        ],
      },
      {
        title: {
          text: '% of subscription plans',
        },

        xAxis: {
          categories: ['iOS only', 'Android only'],
        },
        labels: {
          items: [
            {
              // html: '% of subscribers',
              style: {
                left: '50px',
                top: '18px',
                color:
                  // theme
                  (Highcharts.defaultOptions?.title?.style && Highcharts.defaultOptions?.title?.style.color) || 'black',
              },
            },
          ],
        },
        series: [],
      },
      {
        title: {
          text: '% of subscriptions',
        },
        xAxis: {
          categories: ['iOS only', 'Android only'],
        },
        labels: {
          items: [
            {
              // html: '% of subscribers',
              style: {
                left: '50px',
                top: '18px',
                color:
                  // theme
                  (Highcharts.defaultOptions?.title?.style && Highcharts.defaultOptions?.title?.style?.color) ||
                  'black',
              },
            },
          ],
        },
        chart: {
          plotBackgroundColor: null,
          plotBorderWidth: null,
          plotShadow: false,
          type: 'pie',
        },
        plotOptions: {
          pie: {
            allowPointSelect: true,
            cursor: 'pointer',
            dataLabels: {
              enabled: true,
              format: '<b>{point.name}</b>: {point.percentage:.1f} %',
            },
          },
        },
        series: [{ data: [] }],
      },
      {
        title: {
          text: 'Average age',
        },
        xAxis: {
          minRange: 1,
          scrollbar: {
            enabled: false,
          },
          type: 'datetime',
          opposite: true,
        },
        legend: {
          enabled: true,
        },
        series: [{ data: [] }],
      },
      {
        title: {
          text: 'Cancellation % by plan',
        },
        chart: {
          type: 'column',
        },
        xAxis: {
          categories,
          ordinal: true,
          labels: {
            enabled: true,
          },
        },
        yAxis: {
          title: {
            text: '%',
          },
        },
        plotOptions: {
          column: {
            stacking: 'percent',
          },
        },
        tooltip: {
          pointFormat:
            '<span style="color:{series.color}">{series.name}</span>: <b>{point.y}</b> ({point.percentage:.0f}%)<br/>',
          shared: true,
        },
        series: [],
      },
      {
        chart: {
          type: 'column',
        },
        title: {
          text: 'Cancellation % by source',
        },
        xAxis: {
          categories,
          ordinal: true,
          labels: {
            enabled: true,
          },
        },
        yAxis: {
          title: {
            text: '%',
          },
        },
        plotOptions: {
          column: {
            stacking: 'percent',
          },
        },
        tooltip: {
          pointFormat:
            '<span style="color:{series.color}">{series.name}</span>: <b>{point.y}</b> ({point.percentage:.0f}%)<br/>',
          shared: true,
        },
        series: [],
      },
      {
        navigator: {
          enabled: false,
        },
        scrollbar: {
          enabled: false,
        },
        xAxis: {
          scrollbar: {
            enabled: false,
          },
          type: 'datetime',
          opposite: true,
        },
        ...rangeSelector,
        yAxis: {
          title: {
            text: 'Conversion, %',
          },
          max: 101.0,
        },
        title: {
          text: 'Conversion - Signup to Subscription',
        },
        tooltip: {
          pointFormat: '<b>{point.y:.1f}%</b>',
        },
        legend: {
          enabled: true,
        },
        plotOptions: {
          series: {
            showInLegend: true,
          },
        },
        series: [{ data: [] }],
      },
      {
        title: {
          text: '% of subscriptions per city type',
        },
        tooltip: {
          pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>',
        },
        plotOptions: {
          series: {
            dataLabels: {
              enabled: true,
              format: '{point.name}: {point.percentage:.1f}%',
            },
          },
        },

        series: [
          {
            type: 'pie',
            data: [],
            size: 200,
            showInLegend: false,
            dataLabels: {
              enabled: true,
            },
            tooltip: {
              pointFormat: '<b>{point.percentage:.1f}%</b>',
            },
          },
        ],
      },
      {
        navigator: {
          enabled: false,
        },
        scrollbar: {
          enabled: false,
        },
        ...rangeSelector,
        title: {
          text: 'New subscriptions',
        },

        series: [
          {
            name: 'New subscriptions',
            data: [],
            tooltip: {
              valueDecimals: 2,
            },
          },
        ],
      },
      {
        navigator: {
          enabled: false,
        },
        scrollbar: {
          enabled: false,
        },
        ...rangeSelector,
        title: {
          text: 'Number of users added per day',
        },

        series: [
          {
            name: 'Number of users',
            data: [],
            tooltip: {
              valueDecimals: 0,
            },
          },
        ],
      },
      {
        navigator: {
          enabled: false,
        },
        scrollbar: {
          enabled: false,
        },
        ...rangeSelector,
        title: {
          text: 'Acquisition cost per subscription',
        },
        series: [
          {
            name: 'Acquisition cost',
            data: [],
            tooltip: {
              valueDecimals: 2,
              pointFormat: '<b>£{point.y:.2f}</b>',
            },
          },
        ],
      },
      {
        chart: {
          type: 'column',
        },
        title: {
          text: 'Payment dates distribution',
        },
        legend: {
          enabled: false,
        },
        xAxis: {
          type: 'category',
          step: 1,
          labels: {
            rotation: -45,
          },
        },
        yAxis: {
          min: 0,
          title: {
            text: 'Number of subscriptions',
          },
        },
        series: [
          {
            name: 'Number of subscriptions',
            data: [],
            tooltip: {
              pointFormat:
                '<span style="color:{series.color}">{series.name}</span>: <b>{point.y}</b> ({point.custom_perc:.2f}%)<br/>',
            },
          },
        ],
      },
      {
        chart: {
          type: 'column',
        },
        title: {
          text: 'Net revenue per month',
        },
        legend: {
          enabled: false,
        },
        xAxis: {
          type: 'category',
          step: 1,
          labels: {
            rotation: -45,
          },
        },
        yAxis: {
          min: 0,
          title: {
            text: 'Net (£)',
          },
        },

        series: [
          {
            name: 'Net',
            data: [],
            tooltip: {
              valueDecimals: 2,
              pointFormat: '<b>£{point.y}</b><br/>',
            },
          },
        ],
      },
      {
        chart: {
          type: 'column',
        },
        title: {
          text: 'Net profit per month',
        },
        legend: {
          enabled: false,
        },
        xAxis: {
          type: 'category',
          step: 1,
          labels: {
            rotation: -45,
          },
        },
        yAxis: {
          title: {
            text: 'Profit (£)',
          },
        },

        series: [
          {
            name: 'Net',
            data: [],
            tooltip: {
              valueDecimals: 2,
              pointFormat: '<b>£{point.y}</b><br/>',
            },
          },
        ],
      },
      {
        chart: {
          type: 'column',
        },
        title: {
          text: 'Income and expenses',
        },
        plotOptions: {
          series: {
            grouping: false,
            borderWidth: 0,
          },
        },
        legend: {
          enabled: false,
        },
        tooltip: {
          shared: true,
          headerFormat: '<span style="font-size: 15px">{point.point.name}</span><br/>',
          pointFormat: '<span style="color:{point.color}">\u25CF</span> {series.name}: <b>£{point.y}</b><br/>',
        },
        yAxis: [
          {
            title: {
              text: 'Average (£)',
            },
            showFirstLabel: false,
          },
        ],
        xAxis: {
          type: 'category',
        },
        series: [
          {
            color: 'rgb(158, 159, 163)',
            pointPlacement: -0.2,
            linkedTo: 'main',
            data: [],
            name: 'income',
          },
          {
            name: 'expenses',
            id: 'main',
            dataLabels: [
              {
                enabled: true,
                inside: true,
              },
            ],
            data: [],
          },
        ],
      },
      {
        title: {
          text: 'Credit score per subscription plan',
        },

        xAxis: {
          categories: [''],
        },
        yAxis: {
          title: 'Credit Score',
        },
        labels: {
          items: [
            {
              // html: '% of subscribers',
              style: {
                left: '50px',
                top: '18px',
                color:
                  // theme
                  (Highcharts.defaultOptions?.title?.style && Highcharts.defaultOptions?.title?.style.color) || 'black',
              },
            },
          ],
        },
        series: [],
      },
      {
        title: {
          text: 'Credit score',
        },

        xAxis: {
          categories: ['Source', 'Status'],
        },
        yAxis: {
          title: 'Credit Score',
        },
        labels: {
          items: [
            {
              // html: '% of subscribers',
              style: {
                left: '50px',
                top: '18px',
                color:
                  // theme
                  (Highcharts.defaultOptions?.title?.style && Highcharts.defaultOptions?.title?.style.color) || 'black',
              },
            },
          ],
        },
        series: [],
      },
      {
        title: {
          text: 'Has mortgage',
        },
        tooltip: {
          pointFormat: '{series.name}: <b>{point.y} ({point.percentage:.1f}%)</b>',
        },
        plotOptions: {
          series: {
            dataLabels: {
              enabled: true,
              format: '{point.name}: {point.percentage:.1f}%',
            },
          },
        },

        series: [
          {
            type: 'pie',
            data: [],
            size: 200,
            showInLegend: false,
            dataLabels: {
              enabled: true,
            },
            tooltip: {
              pointFormat: '<b>{point.y} ({point.percentage:.1f}%)</b>',
            },
          },
        ],
      },
      {
        navigator: {
          enabled: false,
        },
        scrollbar: {
          enabled: false,
        },
        title: {
          text: 'Delinquency rate',
        },
        ...rangeSelector,
        xAxis: {
          minRange: 1,
          scrollbar: {
            enabled: false,
          },
          type: 'datetime',
          opposite: true,
        },
        yAxis: {
          title: {
            text: 'Rate, %',
          },
          max: 21.0,
        },
        legend: {
          enabled: true,
        },
        tooltip: {
          pointFormat: '{series.name}: <b>{point.y:.1f}%</b>',
        },
        series: [],
      },
      {
        navigator: {
          enabled: false,
        },
        scrollbar: {
          enabled: false,
        },
        title: {
          text: 'Total store volume (£)',
        },
        ...rangeSelector,
        xAxis: {
          minRange: 1,
          scrollbar: {
            enabled: false,
          },
          type: 'datetime',
          opposite: true,
        },
        yAxis: {
          title: {
            text: '£',
          },
        },
        legend: {
          enabled: true,
        },
        tooltip: {
          pointFormat: '{series.name}: <b>{point.y:.2f}</b>',
        },
        series: [],
      },
      {
        navigator: {
          enabled: false,
        },
        scrollbar: {
          enabled: false,
        },
        ...rangeSelector,
        title: {
          text: 'Store orders per day',
        },

        series: [],
      },
    ],
  };
};
