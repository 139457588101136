import { useRecordContext } from 'react-admin';
import get from 'lodash/get';

export const StripeLink = (props: any) => {
  const { source } = props;
  const record = useRecordContext(props);
  const value = get(record, source);

  if (!value) return <></>;

  if (value?.startsWith('src_')) {
    return (
      <a href={`https://dashboard.stripe.com/sources/${value}`} target={'_blank'} rel={'noopener noreferrer'}>
        {value}
      </a>
    );
  }
  return (
    <a href={`https://dashboard.stripe.com/search?query=${value}`} target={'_blank'} rel={'noopener noreferrer'}>
      {value}
    </a>
  );
};
