import IHandler from './IHandler';
import { GetListParams, GetListResult, GetManyReferenceParams, GetManyReferenceResult, RaRecord } from 'react-admin';
import { getJson } from '../../../utils/api';

export enum HistoryEventType {
  TrialSubscriptionCreated = 'TrialSubscriptionCreateStarted',
  SubscriptionCreateStarted = 'SubscriptionCreateStarted',
  SubscriptionChangeStarted = 'SubscriptionChangeStarted',
  SubscriptionCreateFailed = 'SubscriptionCreateFailed',
  SubscriptionCreated = 'SubscriptionCreated',
  SubscriptionCancelled = 'SubscriptionCancelled',
  SubscriptionUpdated = 'SubscriptionUpdated',
  ChargeFundedCalled = 'ChargeFundedCalled',
  ChargePendingCalled = 'ChargePendingCalled',
  ProcessFundedCalled = 'ProcessFundedCalled',
  ChargeUnpaidCalled = 'ChargeUnpaidCalled',
  CreateOrder = 'CreateOrder',
  CaptureOrder = 'CaptureOrder',
  CancelOrder = 'CancelOrder',
  PaymentMethodChangeFailed = 'PaymentMethodChangeFailed',
  PaymentMethodChanged = 'PaymentMethodChanged',
  AddCreditCalled = 'AddCreditCalled',
  Admin = 'Admin',
  PaymentMethodChangeStarted = 'PaymentMethodChangeStarted',
  WipeBalance = 'WipeBalance',
  SuperchargeSubscriptionCreateStarted = 'SuperchargeSubscriptionCreateStarted',
  DiscountCreated = 'DiscountStarted',
  DiscountDeleted = 'DiscountDeleted',
  AdjustBalance = 'AdjustBalance',
  ChangePaymentDay = 'ChangePaymentDay',
  AdminChangePaymentDay = 'AdminChangePaymentDay',
  AdminChangePaymentDayFailed = 'AdminChangePaymentDayFailed',
  AdminSubscriptionChangeStarted = 'AdminSubscriptionChangeStarted',
  AdminSubscriptionChangeFailed = 'AdminSubscriptionChangeFailed',
}

export default class UserHistoryHandler implements IHandler {
  static prevNextToken: Record<number, string> = {};
  static route = '/admin/history/search';
  static resourceIdName = 'requestId';

  static async getListHandler<RecordType extends RaRecord = any>(
    resource?: string,
    params?: GetListParams
  ): Promise<GetListResult<RecordType>> {
    const query: { [key: string]: any } = {
      ...params?.filter,
      limit: params?.pagination.perPage ?? 10,
      eventTypes:
        (params?.filter?.eventTypes ?? params?.meta?.eventTypes ?? params?.meta?.defaultEventTypes ?? []).join(',') ??
        undefined,
      userId: params?.filter?.userId ?? params?.meta?.userId ?? undefined,
    };

    const page = params?.pagination.page ?? 1;
    if (page > 1) {
      query.nextToken = UserHistoryHandler.prevNextToken[page - 1];
    }

    for (const param in query) {
      if (query[param] === undefined || query[param] === null || query[param] === '') {
        delete query[param];
      }
    }

    return getJson(`${this.route}?${new URLSearchParams(query).toString()}`, process.env.REACT_APP_API_BASE_URL).then(
      async (response) => {
        let { data: responseData } = await response.json();
        const nextToken = responseData?.nextToken;
        UserHistoryHandler.prevNextToken[page] = JSON.stringify(nextToken);
        responseData = responseData?.events?.map((data: any) => {
          const result = {
            ...data,
          };

          result.id = data[this.resourceIdName];
          delete result[this.resourceIdName];
          return {
            ...result,
          };
        });

        return {
          data: responseData,
          pageInfo: {
            hasNextPage: !!nextToken,
            nextPageToken: nextToken ?? undefined,
          },
        };
      }
    );
  }

  static async getManyReference<RecordType extends RaRecord = any>(
    resource: string,
    params: GetManyReferenceParams
  ): Promise<GetManyReferenceResult<RecordType>> {
    return UserHistoryHandler.getListHandler(resource, {
      filter: {
        userId: params?.id,
        ...params.filter,
      },
      meta: params.meta,
      pagination: params.pagination,
      sort: params.sort,
    });
  }
}
