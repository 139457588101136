import { NumberInput, useRecordContext } from 'react-admin';
import { Card, CardContent } from '@mui/material';
import { Edit, SimpleForm, Toolbar, SaveButton } from 'react-admin';

const CrawlerConfigEditToolbar = (props: any) => (
  <Toolbar {...props}>
    <SaveButton />
  </Toolbar>
);

export const CrawlerConfigEdit = () => {
  const ProviderView = () => {
    const record = useRecordContext();
    if (!record) return null;
    return <pre>{JSON.stringify(record.provider, null, '\t')}</pre>;
  };
  return (
    <Card>
      <CardContent>
        <ProviderView />
        <Edit title="CrawlerConfig" resource="settings" actions={false} id={'crawler'} redirect={'/config/crawler'}>
          <SimpleForm toolbar={<CrawlerConfigEditToolbar />}>
            <NumberInput source="priceMarginPercent" />
            <NumberInput source="priceMarginAmount" />
            <NumberInput source="minPrice" />
            <NumberInput source="maxPrice" />
          </SimpleForm>
        </Edit>
      </CardContent>
    </Card>
  );
};
