import IHandler from './IHandler';
import {
  GetListParams,
  GetListResult,
  GetManyParams,
  GetManyResult,
  GetOneParams,
  GetOneResult,
  RaRecord,
  UpdateParams,
  UpdateResult,
} from 'react-admin';
import { getJson, postJson, putJson } from '../../../utils/api';
import Phone3DSHandler from './Phone3DSHandler';

const getChangedData = (data: Record<string, any>, previousData: Record<string, any>): Record<string, any> => {
  const changedData: Record<string, any> = {};

  for (const key in data) {
    if (data[key] === null && data[key] !== previousData[key]) {
      changedData[key] = data[key];
      continue;
    }

    if (typeof data[key] === 'object' && key !== 'comments') {
      continue;
    }

    if (data[key] !== previousData[key]) {
      changedData[key] = data[key];
      continue;
    }
  }

  return changedData;
};

export default class UserListHandler implements IHandler {
  static prevNextToken: Record<number, string> = {};
  static route = '/admin/users';
  static resourceIdName = 'membershipNumber';

  static async getListHandler<RecordType extends RaRecord = any>(
    resource?: string,
    params?: GetListParams
  ): Promise<GetListResult<RecordType>> {
    const query: { [key: string]: any } = {
      limit: params?.pagination.perPage ?? 100,
      ...params?.filter,
    };

    const page = params?.pagination.page ?? 1;
    if (page > 1 && UserListHandler.prevNextToken[page - 1] != null) {
      query['nextToken'] = UserListHandler.prevNextToken[page - 1];
    }

    return postJson(`${this.route}/search`, query, process.env.REACT_APP_API_BASE_URL)
      .then(async (response) => {
        let { data: responseData } = await response.json();
        const nextToken = responseData?.nextToken;
        UserListHandler.prevNextToken[page] = JSON.stringify(nextToken);
        responseData = responseData?.users?.map((data: any) => {
          const result = {
            ...data,
          };

          result.id = data[this.resourceIdName];
          delete result[this.resourceIdName];
          return {
            ...result,
          };
        });
        return {
          data: responseData,
          pageInfo: {
            hasNextPage: !!nextToken,
            nextPageToken: nextToken ?? undefined,
          },
        };
      })
      .catch((response) => {
        if (response.status === 400) {
          return {
            data: [],
            total: 0,
          };
        }
        return Promise.reject({
          status: response.status,
          error: response.statusText,
          message: response.statusText,
        });
      });
  }

  static async getOneHandler<RecordType extends RaRecord = any>(
    resource: string,
    params?: GetOneParams
  ): Promise<GetOneResult<RecordType>> {
    const id = params?.id;
    return getJson(`${this.route}/${id}`, process.env.REACT_APP_API_BASE_URL).then(async (response) => {
      const { data } = await response.json();

      data.id = data[this.resourceIdName];
      delete data[this.resourceIdName];
      return {
        data: {
          ...data,
        },
      };
    });
  }

  static async updateHandler<RecordType extends RaRecord = any>(
    resource: string,
    params?: UpdateParams<any>
  ): Promise<UpdateResult<RecordType>> {
    const payload = params?.data || {};
    const previousPayload = params?.previousData || {};
    const changedData = getChangedData(payload, previousPayload);

    return putJson(`${this.route}/${params?.id}`, changedData).then(async (response) => {
      const { data } = await response.json();

      data.id = data[this.resourceIdName];
      delete data[this.resourceIdName];
      if (changedData.phoneNumber != null) {
        await Phone3DSHandler.sync(
          params?.id,
          () => {},
          () => {}
        );
      }
      return {
        data: {
          ...data,
        },
      };
    });
  }

  static async blockUser(userId: string, onSuccess: () => void, onFailure: (err: string) => void) {
    return postJson(`/admin/users/${userId}/block`, {}, process.env.REACT_APP_API_BASE_URL)
      .then(async (response) => {
        const { data } = await response.json();

        const d = data;
        d.id = d[this.resourceIdName];
        delete d[this.resourceIdName];
        onSuccess();
        return {
          data: {
            ...d,
          },
        };
      })
      .catch((response) => {
        onFailure(
          `${response.status}:${response.error.message ?? JSON.stringify(response.error, null, 2)}:${response.message}`
        );
        return Promise.reject({
          status: response.status,
          error: response.statusText,
          message: response.statusText,
        });
      });
  }

  static async setTag(
    userId: string,
    name: string,
    enabled: boolean,
    reason: string,
    onSuccess: () => void,
    onFailure: (err: string) => void
  ) {
    return postJson(`/admin/users/${userId}/tags`, { name, reason, enabled }, process.env.REACT_APP_API_BASE_URL)
      .then(async (response) => {
        const { data } = await response.json();

        const d = data ?? {};
        d.id = userId;
        delete d[this.resourceIdName];
        onSuccess();
        return {
          data: {
            ...d,
          },
        };
      })
      .catch((response) => {
        onFailure(
          `${response.status}:${response.error.message ?? JSON.stringify(response.error, null, 2)}:${response.message}`
        );
        return Promise.reject({
          status: response.status,
          error: response.statusText,
          message: response.statusText,
        });
      });
  }

  static async updateUserEmail(userId: string, email: string, onSuccess: () => void, onFailure: (err: string) => void) {
    return putJson(`/admin/users/${userId}/email`, { email }, process.env.REACT_APP_API_BASE_URL)
      .then(async (response) => {
        const { data } = await response.json();

        const d = data;
        d.id = d[this.resourceIdName];
        delete d[this.resourceIdName];
        onSuccess();
        return {
          data: {
            ...d,
          },
        };
      })
      .catch((response) => {
        onFailure(
          `${response.status}:${response.error.message ?? JSON.stringify(response.error, null, 2)}:${response.message}`
        );
        return Promise.reject({
          status: response.status,
          error: response.statusText,
          message: response.statusText,
        });
      });
  }

  static async getMany<RecordType extends RaRecord = any>(
    resource: string,
    params: GetManyParams
  ): Promise<GetManyResult<RecordType>> {
    const { ids } = params;
    // @todo update search call to accept multiple ids

    const results = await Promise.all(ids.map((id) => this.getOneHandler(resource, { id }).catch(() => null)));
    return {
      data: results.filter((r) => r != null).map((r) => r?.data),
    };
  }
}
