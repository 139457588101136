import { createContext, useContext } from 'react';
import { KycApi } from '../utils/KycApi';

const ApiContext = createContext({
  kyc: new KycApi(),
});

export default function useApi() {
  return useContext(ApiContext);
}
