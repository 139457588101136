import { Datagrid, DateField, List, Pagination, SelectInput, TextField, TextInput } from 'react-admin';
import { UserScoreStatus } from '../../utils/ScoreApi';

const PostPagination = () => <Pagination rowsPerPageOptions={[10, 25, 50, 100]} />;

const filters = [
  <TextInput source="userId" alwaysOn name="userId" />,
  <SelectInput
    source="status"
    choices={[
      { id: UserScoreStatus.enabled, name: 'enabled' },
      { id: UserScoreStatus.pending, name: 'pending' },
      { id: UserScoreStatus.failed, name: 'failed' },
      { id: UserScoreStatus.disabled, name: 'disabled' },
    ]}
    resettable
    alwaysOn
  />,
];

export const ScoreList = () => {
  return (
    <List resource="score" pagination={<PostPagination />} hasCreate={false} exporter={false} filters={filters}>
      <div>
        <strong>Enabled</strong> - the feature is activated <strong>Pending</strong> - KYC is in progress/abandoned
      </div>
      <div>
        <strong>Failed</strong> - could not get data from Equifax <strong>Disabled</strong> - KYC failed
      </div>
      <Datagrid>
        <TextField source="id" />
        <TextField source="serial" />
        <TextField source="userDetails.firstName" />
        <TextField source="userDetails.lastName" />
        <TextField source="userDetails.email" />
        <DateField source="dateCreate" showTime />
        <DateField source="dateActivate" showTime />
        <TextField source="status" />
        <TextField source="lastError" />
      </Datagrid>
    </List>
  );
};
