import {
  ChipField,
  Datagrid,
  DateField,
  DateInput,
  FilterButton,
  List,
  NumberInput,
  Pagination,
  TextField,
  TextInput,
  TopToolbar,
  useCreatePath,
  useNotify,
  useRecordContext,
} from 'react-admin';
import get from 'lodash/get';
import { Link } from 'react-router-dom';
import { SendReminder } from '../SendReminder';

const ListActions = () => (
  <TopToolbar>
    <FilterButton />
    <SendReminder />
  </TopToolbar>
);

const ListBulkActions = () => (
  <>
    <SendReminder />
  </>
);

const PostPagination = () => <Pagination rowsPerPageOptions={[50, 100, 500]} perPage={50} />;

const filters = [
  <NumberInput source="daysOutstandingLess" label="DaysOutstanding Smaller" name="daysOutstandingLess" />,
  <NumberInput source="daysOutstandingMore" label="DaysOutstanding Greater" name="daysOutstandingMore" />,
  <TextInput source="unpaidAmount" name="unpaidAmount" />,
  <DateInput source="dateStart" name="dateStart" />,
];

const UserLink = (props: any) => {
  const { source } = props;
  const record = useRecordContext(props);
  const value = get(record, source);

  const userShowPage = `/users/${value}/show`;
  return <Link to={userShowPage}>{value}</Link>;
};

export const SubscriptionPlanField = (props: any) => {
  const createPath = useCreatePath();
  const record = useRecordContext(props);
  const value = get(record, 'plan.amount');

  const { id } = record;
  return <Link to={createPath({ resource: 'subscriptions', type: 'show', id: id })}>{value}</Link>;
};

export const NumberOfPaymentsUnpaidField = (props: any) => {
  const record = useRecordContext(props);

  const { source } = props;
  const value = get(record, source);

  const { id } = record;
  return <Link to={`/subscriptions/${id}/payments`}>{value}</Link>;
};

export const SubscriptionListReminder = () => {
  const notify = useNotify();
  return (
    <List
      resource="subscriptions"
      pagination={<PostPagination />}
      actions={<ListActions />}
      hasCreate={false}
      exporter={false}
      filters={filters}
      debounce={500}
      queryOptions={{
        meta: {
          status: 'past_due',
        },
        onError: (error: any) => {
          notify(`${error?.error?.errorMessage ?? 'Internal error'}`, { type: 'error' });
        },
      }}
    >
      <Datagrid bulkActionButtons={<ListBulkActions />}>
        <UserLink source="id" name="User" />
        <ChipField source="status" />
        <DateField source="startedDate" />
        <SubscriptionPlanField source="plan.amount" name="Subscription" />
        <TextField source="balance.unpaidAmount" />
        <NumberOfPaymentsUnpaidField source="numberOfPaymentsUnpaid" />
        <DateField source="oldestUnpaidPaymentDate" />
      </Datagrid>
    </List>
  );
};
