import IHandler from './IHandler';
import {
  CreateParams,
  CreateResult,
  DeleteParams,
  DeleteResult,
  GetListParams,
  GetListResult,
  RaRecord,
} from 'react-admin';
import { deleteJson, getJson, postForm } from '../../../utils/api';

export default class DocumentHandler implements IHandler {
  static prevNextToken: Record<number, string> = {};
  static route = '/admin/users/';
  static resourceIdName = 'userId';

  static async getListHandler<RecordType extends RaRecord = any>(
    resource?: string,
    params?: GetListParams
  ): Promise<GetListResult<RecordType>> {
    const query: { [key: string]: any } = {
      limit: params?.pagination.perPage ?? 10,
      ...params?.filter,
    };

    const page = params?.pagination.page ?? 1;
    if (page > 1 && DocumentHandler.prevNextToken[page - 1] != null) {
      query['nextToken'] = DocumentHandler.prevNextToken[page - 1];
    }
    return getJson(
      `${this.route}${params!.meta.userId!}/documents?${new URLSearchParams(query).toString()}`,
      process.env.REACT_APP_API_BASE_URL
    )
      .then(async (response) => {
        let { data: responseData } = await response.json();
        const nextToken = responseData?.nextToken;
        DocumentHandler.prevNextToken[page] = JSON.stringify(nextToken);
        responseData = responseData?.documents?.map((data: any) => {
          const result = {
            ...data,
          };

          result.id = data[this.resourceIdName];
          delete result[this.resourceIdName];
          return {
            ...result,
          };
        });
        return {
          data: responseData,
          pageInfo: {
            hasNextPage: !!nextToken,
            nextPageToken: nextToken ?? undefined,
          },
        };
      })
      .catch((response) => {
        if (response.status === 400) {
          return {
            data: [],
            total: 0,
          };
        }
        return Promise.reject({
          status: response.status,
          error: response.statusText,
          message: response.statusText,
        });
      });
  }

  static async createHandler<RecordType extends RaRecord = any>(
    resource?: string,
    params?: CreateParams<any>
  ): Promise<CreateResult<RecordType>> {
    const payload = params?.data || {};
    const formData = new FormData();

    for (const name in payload) {
      if (payload[name].rawFile) {
        formData.append(name, payload[name].rawFile, payload[name].rawFile.name);
      } else {
        formData.append(name, payload[name]);
      }
    }

    return postForm(`${this.route}${params!.meta.userId!}/documents`, formData).then(async (response) => {
      const { data } = await response.json();

      data.id = data[this.resourceIdName];
      delete data[this.resourceIdName];

      return {
        data: {
          ...data,
          id: '',
        },
      };
    });
  }

  static async deleteHandler<RecordType extends RaRecord = any>(
    resource?: string,
    params?: DeleteParams<any>
  ): Promise<DeleteResult<RecordType>> {
    const id = params?.id ?? params?.previousData?.id ?? params?.previousData?.documentId ?? '';

    return deleteJson(`${this.route}${params!.meta.userId!}/documents/${id}`, {}).then(async (response) => {
      const { data } = await response.json();

      return {
        data: {
          id: '',
          ...data,
        },
      };
    });
  }
}
