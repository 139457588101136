import { useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField } from '@mui/material';
import IconCancel from '@mui/icons-material/Cancel';
import IconSave from '@mui/icons-material/Save';
import { useListContext, useNotify } from 'react-admin';
import SubscriptionHandler from '../../react-admin/providers/handlers/SubscriptionHandler';

const useSendReminder = () => {
  const { selectedIds, filterValues } = useListContext();
  const [show, setShow] = useState(false);
  const [message, setMessage] = useState(
    "Hi - it's Bits. Please make your payment in the Bits app so that we can report you as up-to-date with the CRAs."
  );
  const [loading, setLoading] = useState(false);
  const notify = useNotify();
  const close = () => {
    setLoading(false);
    setShow(false);
  };

  const sendReminder = async () => {
    setLoading(true);
    SubscriptionHandler.sendReminder(
      {
        message,
        userIds: selectedIds ?? [],
        ...filterValues,
      },
      () => {
        notify('Started the process of sending reminders.');
        close();
      },
      (e: any) => {
        notify(e.toString(), { type: 'error' });
        close();
      }
    );
  };

  return {
    sendReminder,
    loading,
    setLoading,
    show,
    setShow,
    close,
    selectedIds,
    message,
    setMessage,
  };
};

export const SendReminder = () => {
  const { show, setShow, loading, sendReminder, close, selectedIds, message, setMessage } = useSendReminder();

  return (
    <>
      <Button color="primary" onClick={() => setShow(true)}>
        Reminder
      </Button>
      <Dialog fullWidth open={show} onClose={setShow} aria-label="Send Reminder">
        <DialogTitle>Reminder</DialogTitle>
        <DialogContent>
          <Grid item xs={12}>
            {selectedIds?.length ? (
              <p>This action will send SMS message to {selectedIds.length} users.</p>
            ) : (
              <p>This action will send SMS message to all filteres users.</p>
            )}
            <TextField
              id="message"
              placeholder="Message"
              variant="outlined"
              fullWidth
              multiline
              value={message}
              onChange={(event) => setMessage(event.target.value)}
            />
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={close} disabled={loading}>
            <IconCancel />
            <span>Cancel</span>
          </Button>
          <Button onClick={sendReminder} disabled={loading}>
            <IconSave />
            <span>Send</span>
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
