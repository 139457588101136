import {
  Datagrid,
  List,
  ListButton,
  Pagination,
  SelectInput,
  TextField,
  TextInput,
  useRecordContext,
} from 'react-admin';
import { UserInitialKycStatus, UserKycStatus } from '../../utils/KycApi';
import { KycApproveAction } from '../KycApprove';
import { useResourceAccess } from '../../hooks/useAccess';

const PostPagination = () => <Pagination rowsPerPageOptions={[10, 25, 50, 100]} />;

const filters = [
  <TextInput source="userId" alwaysOn name="userId" />,
  <SelectInput
    source="kycStatus"
    choices={[
      { id: UserKycStatus.kycApproved, name: 'approved' },
      { id: UserKycStatus.kycDeclined, name: 'declined' },
    ]}
    resettable
    alwaysOn
  />,
  <SelectInput
    source="amlStatus"
    choices={[
      { id: UserInitialKycStatus.approved, name: 'approved' },
      { id: UserInitialKycStatus.denied, name: 'declined' },
    ]}
    resettable
    alwaysOn
  />,
];

export const LinkToHistory = () => {
  const record = useRecordContext();
  const { id } = record;
  return <ListButton to={`/kyc/${id}/history`} label={'History'} />;
};

export const KycList = () => {
  const { canEditKYC } = useResourceAccess();

  return (
    <List resource="kyc" pagination={<PostPagination />} hasCreate={false} exporter={false} filters={filters}>
      <Datagrid>
        <TextField source="id" />
        <TextField source="userDetails.firstName" />
        <TextField source="userDetails.lastName" />
        <TextField source="userDetails.email" />
        <TextField source="amlStatus" />
        {canEditKYC() && <KycApproveAction type="aml"></KycApproveAction>}
        <TextField source="kycStatus" />
        {canEditKYC() && <KycApproveAction type="kyc"></KycApproveAction>}
        <LinkToHistory />
      </Datagrid>
    </List>
  );
};
