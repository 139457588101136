import { BooleanInput, Edit, NumberInput, Toolbar, SaveButton, SelectInput, SimpleForm, TextInput } from 'react-admin';
import { Card, CardContent } from '@mui/material';
import { BackButton } from '../../react-admin/components/BackButton';

export const PlanEditForm = () => (
  <SimpleForm
    toolbar={
      <Toolbar>
        <SaveButton />
      </Toolbar>
    }
  >
    <TextInput source="name" name="name" />
    <TextInput source="title" name="title" />
    <TextInput source="subtitle" name="subtitle" />
    <TextInput source="availability" name="" />
    <NumberInput source="amount" name="" />
    <NumberInput source="creditAmount" name="" />
    <NumberInput source="order" name="" />
    <SelectInput
      source="currency"
      name="currency"
      choices={[
        { id: 'GBP', name: 'GBP' },
        { id: 'USD', name: 'USD' },
      ]}
    />
    <SelectInput
      source="interval"
      name="interval"
      choices={[
        { id: 'day', name: 'day' },
        { id: 'month', name: 'month' },
        { id: 'week', name: 'week' },
        { id: 'year', name: 'year' },
      ]}
    />
    <BooleanInput source="isDefault" name="isDefault" />
    <BooleanInput source="isEnabled" name="isEnabled" />
    <TextInput source="stripePlanId" name="stripePlanId" />
    <TextInput source="renewalPeriod" name="renewalPeriod" />
    <NumberInput name="orderLimitPerHour" source="orderLimitPerHour" min={1} />
    <NumberInput name="orderLimitPerDay" source="orderLimitPerDay" min={1} />
    <NumberInput name="orderLimitPerPeriod" source="orderLimitPerPeriod" min={1} />
    <NumberInput name="creditCardLimit" source="creditCardLimit" min={1} max={500} />
    <BackButton />
  </SimpleForm>
);

export const SubscriptionPlanEdit = () => (
  <Card>
    <CardContent>
      <Edit title="Edit subscription plan" resource="plans" mutationMode="pessimistic">
        <PlanEditForm />
      </Edit>
    </CardContent>
  </Card>
);
