import {
  List,
  Datagrid,
  TextField,
  SelectInput,
  TextInput,
  DateInput,
  ShowButton,
  TopToolbar,
  useRecordContext,
  ChipField,
} from 'react-admin';
import { Pagination } from 'react-admin';
import { ChargeAll } from '../ChargeAll';
import { LinkToPayments } from '../LinkToPayments';
import get from 'lodash/get';
import { Link } from 'react-router-dom';
import { useResourceAccess } from '../../hooks/useAccess';

const UserLink = (props: any) => {
  const { source } = props;
  const record = useRecordContext(props);
  const value = get(record, source);

  const userShowPage = `/users/${value}/show`;
  return <Link to={userShowPage}>{value}</Link>;
};

const SubscriptionLink = (props: any) => {
  const { source } = props;
  const record = useRecordContext(props);
  const { id } = record;
  const value = get(record, source);

  const subscriptionShowPage = `/subscriptions/${id}/show`;

  return <Link to={subscriptionShowPage}>{value}</Link>;
};

const filters = [
  <TextInput source="userId" alwaysOn name="userId" />,
  <SelectInput
    source="status"
    choices={[
      { id: 'pending', name: 'Pending' },
      { id: 'active', name: 'Active' },
      { id: 'past_due', name: 'Past due' },
    ]}
    resettable
    alwaysOn
  />,
  <DateInput source="dateStart" alwaysOn name="dateStart" />,
];

const SubscriptionListActions = () => {
  const { canChargeAll } = useResourceAccess();
  if (!canChargeAll()) {
    return <></>;
  }

  return (
    <TopToolbar>
      <ChargeAll key="chargeAll" />
    </TopToolbar>
  );
};

const PostPagination = () => <Pagination rowsPerPageOptions={[50, 100, 500]} perPage={50} />;
export const SubscriptionList = () => {
  return (
    <List
      resource="subscriptions"
      pagination={<PostPagination />}
      hasCreate={false}
      exporter={false}
      filters={filters}
      actions={<SubscriptionListActions />}
      debounce={500}
      perPage={50}
    >
      <Datagrid>
        <UserLink source="id" name="User" />
        <ChipField source="status" />
        <TextField source="startedDate" />
        <TextField source="plan.amount" />
        <TextField source="plan.creditAmount" />
        <SubscriptionLink source="balance.availableFunds" />
        <SubscriptionLink source="balance.currentBalance" />
        <SubscriptionLink source="balance.unpaidAmount" />
        <TextField source="cardVerified" label="CardVerified" />
        <ShowButton />
        <LinkToPayments />
      </Datagrid>
    </List>
  );
};
