import IHandler from './IHandler';
import { GetOneParams, GetOneResult, RaRecord } from 'react-admin';
import { getJson, postJson } from '../../../utils/api';

export default class CreditCardProfileHandler implements IHandler {
  static route = '/admin/user';
  static resourceIdName = 'userId';

  static async getOneHandler<RecordType extends RaRecord = any>(
    resource: string,
    params?: GetOneParams
  ): Promise<GetOneResult<RecordType>> {
    const id = params?.id;
    return getJson(`${this.route}/${id}`, process.env.REACT_APP_BASE_CREDIT_CARD_ADMIN_API_URL).then(
      async (response) => {
        const { data } = await response.json();

        const d = data;

        d.id = d[this.resourceIdName];
        delete d[this.resourceIdName];
        return {
          data: {
            ...d,
          },
        };
      }
    );
  }

  static async deactivateSubscription(
    userId: string,
    reason: string,
    onSuccess: () => void,
    onFailure: (err: string) => void
  ) {
    return postJson(
      `/admin/user/${userId}/subscription/deactivate`,
      { reason },
      process.env.REACT_APP_BASE_CREDIT_CARD_ADMIN_API_URL
    )
      .then(async (response) => {
        const { data } = await response.json();

        const d = data;
        d.id = d[this.resourceIdName];
        delete d[this.resourceIdName];
        onSuccess();
        return {
          data: {
            ...d,
          },
        };
      })
      .catch((response) => {
        onFailure(
          `${response.status}:${response.error.message ?? JSON.stringify(response.error, null, 2)}:${response.message}`
        );
        return Promise.reject({
          status: response.status,
          error: response.statusText,
          message: response.statusText,
        });
      });
  }
}
