import {
  DataProvider as RaDataProvider,
  CreateParams,
  CreateResult,
  DeleteManyResult,
  DeleteResult,
  GetListParams,
  GetListResult,
  GetManyReferenceResult,
  GetManyResult,
  GetOneParams,
  GetOneResult,
  RaRecord,
  UpdateManyResult,
  UpdateParams,
  UpdateResult,
  DeleteParams,
  GetManyParams,
  GetManyReferenceParams,
} from 'react-admin';

import { putJson, getJson, postJson } from '../../utils/api';
import DocumentHandler from './handlers/DocumentHandler';
import UserListHandler from './handlers/UserListHandler';
import KycListHandler from './handlers/KycListHandler';
import SettingHandler from './handlers/SettingHandler';
import GroupListHandler from './handlers/GroupListHandler';
import KycDetailsHandler from './handlers/KycDetailsHandler';
import ScoreListHandler from './handlers/ScoreListHandler';
import SubscriptionHandler from './handlers/SubscriptionHandler';
import SubscriptionPlanHandler from './handlers/SubscriptionPlanHandler';
import PaymentHandler from './handlers/PaymentHandler';
import SubscriptionHistoryHandler from './handlers/SubscriptionHistoryHandler';
import UserHistoryHandler from './handlers/UserHistoryHandler';
import CardVerificationHandler from './handlers/CardVerificationHandler';
import CreditReportingHandler from './handlers/CreditReportingHandler';
import StoreSettingsHandler from './handlers/StoreSettingHandler';
import CreditCardProfileHandler from './handlers/CreditCardProfileHandler';
import Phone3DSHandler from './handlers/Phone3DSHandler';
import DataMismatchUserHandler from './handlers/DataMismatchUserHandler';
import PepSanctionsUserHandler from './handlers/PepSanctionsUserHandler';
import UserEmailNotificationListHandler from './handlers/UserEmailNotificationListHandler';
import UserPushNotificationListHandler from './handlers/UserPushNotificationListHandler';
import UserSMSNotificationListHandler from './handlers/UserSMSNotificationListHandler';
import UserHandler from './handlers/UserHandler';

const resourceHandlerMap = new Map<string, any>([
  ['users', UserListHandler],
  /**
   * UserHandler and UserListHandler are using the same endpoint for data retrieval
   * but UserHandler provides different data projection.
   * For more details, see {@link UserHandler}
   */
  ['user', UserHandler],
  ['documents', DocumentHandler],
  ['kyc', KycListHandler],
  ['score', ScoreListHandler],
  ['kycDetails', KycDetailsHandler],
  ['settings', SettingHandler],
  ['groups', GroupListHandler],
  ['subscriptions', SubscriptionHandler],
  ['plans', SubscriptionPlanHandler],
  ['payments', PaymentHandler],
  ['subscriptionhistory', SubscriptionHistoryHandler],
  ['userhistory', UserHistoryHandler],
  ['cardverification', CardVerificationHandler],
  ['creditreporting', CreditReportingHandler],
  ['storesettings', StoreSettingsHandler],
  ['creditcardprofile', CreditCardProfileHandler],
  ['phone3ds', Phone3DSHandler],
  ['kycmismatch', DataMismatchUserHandler],
  ['sanctions', PepSanctionsUserHandler],
  ['emailnotifications', UserEmailNotificationListHandler],
  ['pushnotifications', UserPushNotificationListHandler],
  ['smsnotifications', UserSMSNotificationListHandler],
]);

const getResourceName = (resource: string): string => {
  const resourceComponents = resource.split('/');
  return resourceComponents.length > 0 ? resourceComponents[resourceComponents.length - 1] : resource;
};

class DataProvider implements RaDataProvider {
  async getList<RecordType extends RaRecord = any>(
    resource: string,
    params: GetListParams
  ): Promise<GetListResult<RecordType>> {
    const resourceName = getResourceName(resource);
    const handler = resourceHandlerMap.get(resourceName);
    if (handler?.getListHandler) {
      return handler.getListHandler(resource, params);
    }

    return getJson(`/${resource}`).then(async (response) => {
      let { data: responseData } = await response.json();

      responseData = responseData.Items ?? responseData;
      responseData = responseData?.length > 0 ? responseData : [];

      return {
        data: responseData,
        total: responseData.length,
      };
    });
  }

  async getOne<RecordType extends RaRecord = any>(
    resource: string,
    params: GetOneParams<any>
  ): Promise<GetOneResult<RecordType>> {
    const { id } = params;
    params.id = id === 'undefined' ? '' : id;

    const resourceName = getResourceName(resource);
    const handler = resourceHandlerMap.get(resourceName);
    if (handler?.getOneHandler) {
      return handler.getOneHandler(resource, params);
    }

    return getJson(`/${resource}/${id}`).then(async (response) => {
      const { data } = await response.json();
      return {
        data,
      };
    });
  }

  async getMany<RecordType extends RaRecord = any>(
    resource: string,
    params: GetManyParams
  ): Promise<GetManyResult<RecordType>> {
    const resourceName = getResourceName(resource);
    const handler = resourceHandlerMap.get(resourceName);
    if (handler?.getMany) {
      return handler.getMany(resource, params);
    }

    throw new Error('Method not available');
  }

  async getManyReference<RecordType extends RaRecord = any>(
    resource: string,
    params: GetManyReferenceParams
  ): Promise<GetManyReferenceResult<RecordType>> {
    const resourceName = getResourceName(resource);
    const handler = resourceHandlerMap.get(resourceName);
    if (handler?.getManyReference) {
      return handler.getManyReference(resource, params);
    }

    return Promise.reject(new Error('Method not available'));
  }

  async update<RecordType extends RaRecord = any>(
    resource: string,
    params: UpdateParams<any>
  ): Promise<UpdateResult<RecordType>> {
    const { id } = params;
    params.id = id === 'undefined' ? '' : id;

    const resourceName = getResourceName(resource);
    const handler = resourceHandlerMap.get(resourceName);
    if (handler?.updateHandler) {
      return handler.updateHandler(resource, params);
    }
    const payload = params.data;

    return putJson(`/${resource}/${id}`, payload).then(async (response) => {
      const { data } = await response.json();

      return {
        data,
      };
    });
  }

  // this should update an array of ids, the api doesn't have this feature
  async updateMany<RecordType extends RaRecord = any>(): Promise<UpdateManyResult<RecordType>> {
    return Promise.reject(new Error('Method not available'));
  }

  async create<RecordType extends RaRecord = any>(
    resource: string,
    params: CreateParams<any>
  ): Promise<CreateResult<RecordType>> {
    const payload = params.data;

    const resourceName = getResourceName(resource);
    const handler = resourceHandlerMap.get(resourceName);
    if (handler?.createHandler) {
      return handler.createHandler(resource, params);
    }

    return postJson(`/${resource}`, payload).then(async (response) => {
      const { data } = await response.json();

      return {
        data,
      };
    });
  }

  async delete<RecordType extends RaRecord = any>(
    resource: string,
    params: DeleteParams<any>
  ): Promise<DeleteResult<RecordType>> {
    const resourceName = getResourceName(resource);
    const handler = resourceHandlerMap.get(resourceName);
    if (handler?.deleteHandler) {
      return handler.deleteHandler(resource, params);
    }

    return Promise.reject(new Error('Method not available'));
  }

  // this should delete an array of ids, the api doesn't have this feature
  async deleteMany<RecordType extends RaRecord = any>(): Promise<DeleteManyResult<RecordType>> {
    return Promise.reject(new Error('Method not available'));
  }
}

const dataProvider = new DataProvider();

export default dataProvider;
