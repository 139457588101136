import { CreateButton, Datagrid, DateField, Link, List, TextField, TopToolbar, useRecordContext } from 'react-admin';
import get from 'lodash/get';

const UserLink = (props: any) => {
  const { source } = props;
  const record = useRecordContext(props);
  const value = get(record, source);

  const userShowPage = `/users/${value}/show`;
  return <Link to={userShowPage}>{value}</Link>;
};

const PepSanctionsUserListActions = () => {
  return <></>;
};

const filters: any[] = [];

const FileUploadButton = () => {
  return <CreateButton label="Upload sactions file" to={`/sanctions/create`} />;
};

const PepSanctionsListActions = () => {
  return (
    <TopToolbar>
      <FileUploadButton />
    </TopToolbar>
  );
};

export const PepSanctionsUserList = () => {
  return (
    <List resource="sanctions" title="KYC Pep/Sanctions hits" filters={filters} actions={<PepSanctionsListActions />}>
      <Datagrid bulkActionButtons={<PepSanctionsUserListActions />}>
        <UserLink source="id" label={'User'} />
        <TextField source="firstName" />
        <TextField source="lastName" />
        <DateField source="dateCreate" />
        <TextField source="sanctionType" />
      </Datagrid>
    </List>
  );
};
