import { GetOneParams, GetOneResult, RaRecord, UpdateParams, UpdateResult } from 'react-admin';
import { getJson, putJson } from '../../../utils/api';
import IHandler from './IHandler';

export default class SettingHandler implements IHandler {
  static route = '/admin/settings';
  static resourceIdName = 'id';

  static async getOneHandler<RecordType extends RaRecord = any>(
    resource: string,
    params: GetOneParams
  ): Promise<GetOneResult<RecordType>> {
    const configId = params.id;
    return getJson(`${this.route}/${configId}`).then(async (response) => {
      const { data } = await response.json();

      return {
        data: {
          ...data,
        },
      };
    });
  }

  static async updateHandler<RecordType extends RaRecord = any>(
    resource: string,
    params?: UpdateParams<any>
  ): Promise<UpdateResult<RecordType>> {
    const payload = params?.data || {};
    return putJson(`${this.route}`, payload).then(async (response) => {
      const { data } = await response.json();

      return {
        data: {
          ...data,
        },
      };
    });
  }
}
