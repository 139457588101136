import { Create, FileField, FileInput, required, SimpleForm } from 'react-admin';

export const SanctionsFileUpload = () => {
  return (
    <Create redirect={`/sanctions`}>
      <SimpleForm>
        <FileInput source="file" label="Upload file" validate={required()} multiple={false}>
          <FileField source="src" title="title" />
        </FileInput>
      </SimpleForm>
    </Create>
  );
};
