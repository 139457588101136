import IHandler from './IHandler';
import {
  CreateParams,
  CreateResult,
  GetListParams,
  GetListResult,
  GetOneParams,
  GetOneResult,
  RaRecord,
} from 'react-admin';
import { getJson, postJson } from '../../../utils/api';

export default class PepSanctionsUserHandler implements IHandler {
  static prevNextToken: Record<number, string> = {};
  static route = '/admin/users/sanctions';
  static resourceIdName = 'userId';

  static async getListHandler<RecordType extends RaRecord = any>(
    resource?: string,
    params?: GetListParams
  ): Promise<GetListResult<RecordType>> {
    const query: { [key: string]: any } = {
      limit: params?.pagination.perPage ?? 10,
      ...params?.filter,
    };

    const page = params?.pagination.page ?? 1;
    if (page > 1 && PepSanctionsUserHandler.prevNextToken[page - 1] != null) {
      query['nextToken'] = PepSanctionsUserHandler.prevNextToken[page - 1];
    }
    return getJson(`${this.route}?${new URLSearchParams(query).toString()}`, process.env.REACT_APP_BASE_KYC_API_URL)
      .then(async (response) => {
        let { data: responseData } = await response.json();
        const nextToken = responseData?.nextToken;
        PepSanctionsUserHandler.prevNextToken[page] = nextToken;
        responseData = responseData?.users?.map((data: any) => {
          const result = {
            ...data,
          };
          result.id = data[this.resourceIdName];
          delete result[this.resourceIdName];
          const sanctionType =
            result.isPepHit && result.isSanctionsHit
              ? 'Both'
              : result.isPepHit
              ? 'Pep'
              : result.isSanctionsHit
              ? 'Sanctions'
              : 'Unknown';
          return {
            ...result,
            sanctionType,
          };
        });

        return {
          data: responseData,
          pageInfo: {
            hasNextPage: !!nextToken,
            nextPageToken: nextToken ?? undefined,
          },
        };
      })
      .catch((response) => {
        if (response.status === 400) {
          return {
            data: [],
            total: 0,
          };
        }
        return Promise.reject({
          status: response.status,
          error: response.statusText,
          message: response.statusText,
        });
      });
  }

  static async getOneHandler<RecordType extends RaRecord = any>(
    resource: string,
    params?: GetOneParams
  ): Promise<GetOneResult<RecordType>> {
    const id = params?.id;
    return getJson(`${this.route}?userId=${id}`, process.env.REACT_APP_BASE_KYC_API_URL).then(async (response) => {
      const { data } = await response.json();

      const d = data.users[0];

      d.id = d[this.resourceIdName];
      delete d[this.resourceIdName];
      console.log(d);
      return {
        data: {
          ...d,
        },
      };
    });
  }

  static async createHandler<RecordType extends RaRecord = any>(
    resource?: string,
    params?: CreateParams<any>
  ): Promise<CreateResult<RecordType>> {
    const payload = params?.data || {};
    const formData = new FormData();

    let fileName = '';
    let file: any;

    for (const name in payload) {
      if (payload[name].rawFile) {
        formData.append(name, payload[name].rawFile, payload[name].rawFile.name);
        fileName = payload[name].rawFile.name;
        file = payload[name].rawFile;
      } else {
        formData.append(name, payload[name]);
      }
    }

    const res = await postJson(`/admin/sanctions`, { fileName });
    const json = await res.json();
    const url = json.data;
    console.log(url);

    await fetch(url, {
      method: 'PUT',
      body: file,
      headers: {
        'Content-Type': 'application/octet-stream',
      },
    });
    return {
      data: {
        id: '',
      } as any,
    };
  }
}
