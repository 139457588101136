import {
  ChipField,
  Datagrid,
  DateField,
  FunctionField,
  List,
  ListButton,
  NumberField,
  ReferenceField,
  Show,
  SimpleShowLayout,
  TabbedShowLayout,
  TextField,
  TopToolbar,
  useGetOne,
  useShowContext,
} from 'react-admin';
import { Grid } from '@mui/material';
import { createDateRenderer } from '../../utils/misc';
import { CancelSubscriptionAction } from '../CancelSubscription';
import { useRecordContext } from 'ra-core';
import { ChangeSubscriptionAction } from '../ChangeSubscription';
import { ChangePaymentDayAction } from '../ChangePaymentDay';
import { ConditionalCustomUrlField } from '../common/ConditionalCustomUrlField';
import { LinkToPayments } from '../LinkToPayments';
import { SubscriptionHistoryEventType } from '../../utils/models';
import { useResourceAccess } from '../../hooks/useAccess';
import { StripeLink } from '../StripeLink';
import { UpdateBalanceAction } from '../UpdateBalanceAction';

const SubscriptionActions = () => {
  const record = useRecordContext();
  const { canEdit, canCancelSubscription, canUpdateBalance } = useResourceAccess();

  if (!canEdit()) {
    return <></>;
  }

  const actions = [];
  if (record && !['unknown', 'processing', 'pending'].includes(record.status)) {
    if (canCancelSubscription()) {
      actions.push(<CancelSubscriptionAction key="cancel" wallet={record} />);
    }
    actions.push(<ChangeSubscriptionAction key="changePlan" wallet={record} />);
    actions.push(<ChangePaymentDayAction key="changePaymentDay" wallet={record} />);
    if (canUpdateBalance()) {
      actions.push(<UpdateBalanceAction key="updateBalance" wallet={record} />);
    }
  }

  return <TopToolbar>{actions}</TopToolbar>;
};

export const HistoryTab = () => {
  const { record } = useShowContext();
  const currency = record.plan?.currency ?? 'GBP';

  return (
    <List
      resource={'subscriptionhistory'}
      actions={false}
      queryOptions={{
        meta: {
          userId: record.id,
          eventTypes: [SubscriptionHistoryEventType.SubscriptionCreateStarted],
        },
      }}
    >
      <Datagrid bulkActionButtons={false}>
        <FunctionField source="timestamp" render={createDateRenderer('timestamp')} label={'Created'} />
        <ReferenceField reference={'plans'} source={'payload.plan.id'} label={'Plan'}>
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField reference={'plans'} source={'payload.plan.id'} label={'Plan Amount'}>
          <NumberField source="amount" options={{ style: 'currency', currency }} />
        </ReferenceField>
        <ReferenceField reference={'plans'} source={'payload.plan.id'} label={'Plan Credit'}>
          <NumberField source="creditAmount" options={{ style: 'currency', currency }} />
        </ReferenceField>
      </Datagrid>
    </List>
  );
};

export const LinkToUser = () => {
  const record = useRecordContext();
  const { id } = record;
  return <ListButton to={`/users/${id}/show`} label={'User'} />;
};

const CreditCardProfileDetails = () => {
  const user = useRecordContext();
  const { id } = user ?? { id: undefined };
  const { data, isLoading, isError, error } = useGetOne(`creditcardprofile`, { id });

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (isError || !data) {
    console.log(`Error loading credit card profile for user ${id}: ${JSON.stringify(error)}. ${error}`);
    if ((error as any).status === 401) {
      return <></>;
    }

    return (
      <>
        {' '}
        <h3>Credit Card</h3>
        <SimpleShowLayout
          record={{
            id,
            status: 'inactive',
            errorMessage: error,
          }}
        >
          <ChipField source="status"></ChipField>
          <TextField source="errorMessage.error.errorMessage" label="Error message" />
        </SimpleShowLayout>
      </>
    );
  }

  return (
    <>
      <h3>Credit Card</h3>
      <SimpleShowLayout
        record={{
          ...data,
        }}
      >
        <ChipField source="status" />
        <DateField source="dateCreate" />
        <DateField source="subscriptionStartDate" />
        <DateField source={'nextPaymentDate'} />
        <DateField source={'nextBillingDate'} />
        <NumberField source={'lastUtilization'} options={{ style: 'percent' }} />
        <NumberField source={'creditLimit'} options={{ style: 'currency', currency: 'GBP' }} />
      </SimpleShowLayout>
    </>
  );
};

export const GeneralTab = () => {
  const { record } = useShowContext();
  const currency = record?.plan?.currency ?? 'GBP';

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={6}>
        <SimpleShowLayout>
          <ChipField source="status" fullWidth />
          <NumberField source="balance.availableFunds" options={{ style: 'currency', currency }} fullWidth />
          <NumberField source="balance.currentBalance" options={{ style: 'currency', currency }} fullWidth />
          <NumberField source="balance.currentBalance" options={{ style: 'currency', currency }} fullWidth />
          <NumberField source="balance.unpaidAmount" options={{ style: 'currency', currency }} fullWidth />
          <FunctionField source="startedDate" render={createDateRenderer('startedDate')} />
          <FunctionField source="lastPaymentDate" render={createDateRenderer('lastPaymentDate')} />
          <FunctionField source="nextPaymentDate" render={createDateRenderer('nextPaymentDate')} />
          <ConditionalCustomUrlField
            source="customerId"
            target="_blank"
            rel="noopener noreferrer"
            prefix="https://dashboard.stripe.com/customers/"
          />
          <ConditionalCustomUrlField
            source="gateway.subscription"
            target="_blank"
            rel="noopener noreferrer"
            prefix="https://dashboard.stripe.com/subscriptions/"
          />
        </SimpleShowLayout>
      </Grid>
      <Grid item xs={12} md={6}>
        <SimpleShowLayout>
          <TextField source="plan.name" />
          <TextField source="plan.title" />
          <TextField source="plan.subtitle" />
          <NumberField source="plan.amount" options={{ style: 'currency', currency }} />
          <NumberField source="plan.creditAmount" options={{ style: 'currency', currency }} />
          <TextField source="plan.currency" />
          <TextField source="plan.interval" />
          <TextField source="plan.renewalPeriod" />
        </SimpleShowLayout>
      </Grid>
      <Grid item xs={12} md={6}>
        <SimpleShowLayout>
          <ChipField source="cardVerified" label="CardVerified" />
          <TextField source="paymentMethod.name" label="Name in card" />
          <TextField source="paymentMethod.fullName" label="Full Name" />
          <StripeLink source="paymentMethod.id" label="Stripe" />
        </SimpleShowLayout>
      </Grid>
      <Grid item xs={12} md={6}>
        <SimpleShowLayout>
          <LinkToPayments />
          <LinkToUser />
        </SimpleShowLayout>
      </Grid>
      <Grid item xs={12} md={6}>
        <CreditCardProfileDetails />
      </Grid>
    </Grid>
  );
};

export const SubscriptionDetails = () => {
  return (
    <Show actions={<SubscriptionActions />}>
      <TabbedShowLayout>
        <TabbedShowLayout.Tab label="General">
          <GeneralTab />
        </TabbedShowLayout.Tab>
        <TabbedShowLayout.Tab label="History">
          <HistoryTab />
        </TabbedShowLayout.Tab>
      </TabbedShowLayout>
    </Show>
  );
};
